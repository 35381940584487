import styled from '@emotion/styled'
import { HeadingsH1, Paragraph14, Subheading20 } from 'styles/typography'
import { SmallBlackButton } from 'components/atoms/button'

//wrapper for the take action text
export const ActionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    color: ${(props) => props.theme.primaryColor.pine[75]};
    gap: ${(props) => props.theme.spacing.gap};
`
//wrapper for heading
export const HeadingWrapper = styled(HeadingsH1)`
    color: ${(props) => props.theme.primaryColor.black[100]};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        font-family: ${(props) => props.theme.typography.headingsH2.fontFamily};
        font-size: ${(props) => props.theme.typography.headingsH2.size};
        font-weight: ${(props) => props.theme.typography.headingsH2.weight};
        line-height: ${(props) => props.theme.typography.headingsH2.lineheight};
        letter-spacing: ${(props) => props.theme.typography.headingsH2.letterSpacing};
    }
`
//wrapper for the target text border
export const TargetWrapper = styled.div`
    width: 100%;
    max-width: 1088px;
    justify-content: center;
    display: flex;
    align-items: center;
    border-top: ${(props) => props.theme.input.border} solid ${(props) => props.theme.primaryColor.black[100]};
    border-bottom: ${(props) => props.theme.input.border} solid ${(props) => props.theme.primaryColor.black[100]};
    color: ${(props) => props.theme.primaryColor.pine[75]};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        text-align: center;
    }
`
//wrapper for the target text
export const TargetText = styled(Subheading20)`
    margin: ${(props) => props.theme.spacing.m} ${(props) => props.theme.spacing.xl};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        margin: ${(props) => props.theme.spacing.m} 0px;
    }
`
//wrapper to resize the svg sdk image
export const SDKWrapper = styled.div`
    height: ${(props) => props.theme.corporate.height.sdkDesktop};
    width: ${(props) => props.theme.corporate.width.sdkDesktop};
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        height: ${(props) => props.theme.corporate.height.sdkMobile};
        width: ${(props) => props.theme.corporate.width.sdkMobile};
    }
`
//wrapper for the flexbox that includes sdk image and connect texts
export const BottomWrapper = styled.div`
    max-width: 1088px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: ${(props) => props.theme.spacing.xxl.default};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        flex-direction: column;
        gap: ${(props) => props.theme.spacing.l};
        align-items: center;
    }
`
//wrapper for connect text spacing
export const ConnectWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${(props) => props.theme.spacing.m};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        align-items: center;
        gap: ${(props) => props.theme.spacing.l};
    }
`
//wrapper for connect text box
export const ConnectTextBox = styled.div`
    width: ${(props) => props.theme.corporate.width.connectTextBox};
    color: ${(props) => props.theme.primaryColor.black[75]};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        width: unset;
    }
`
//wrapper to resize button
export const ConnectButton = styled(SmallBlackButton)`
    padding: unset;
    width: ${(props) => props.theme.corporate.width.button};
    height: ${(props) => props.theme.corporate.height.button};
`
//container for the scope and connect text
export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${(props) => props.theme.spacing.xxl.default};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        gap: ${(props) => props.theme.spacing.l};
        align-items: center;
    }
`
//container for the scope text size
export const ScopeContainer = styled.div`
    width: ${(props) => props.theme.corporate.width.scopeContainerDesktop};
    height: ${(props) => props.theme.corporate.height.scopeContainerDesktop};
    display: flex;
    align-items: center;
    border-left: ${(props) => props.theme.corporate.border.scope} solid ${(props) => props.theme.primaryColor.pine[50]};
    background-color: ${(props) => props.theme.primaryColor.cloud[10]};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        width: ${(props) => props.theme.corporate.width.scopeContainerTablet};
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        width: ${(props) => props.theme.corporate.width.scopeContainerMobile};
        height: ${(props) => props.theme.corporate.height.scopeContainerMobile};
    }
`
//wrapper for the scope text margin
export const ScopeText = styled(Paragraph14)`
    margin: ${(props) => props.theme.spacing.xs} ${(props) => props.theme.spacing.xs};
`
