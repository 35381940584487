import styled from '@emotion/styled'
import bulb from '../../assets/icons/bulb.svg'
import tree from '../../assets/icons/tree.svg'
import car from '../../assets/icons/car.svg'
import { Paragraph16, Subheading24 } from 'styles/typography'
import { Card } from 'components/atoms/card'
import { Pointer } from 'components/atoms/tooltip'
import InfoIcon from '../../assets/icons/info-gray.svg'
import { MapContainer, TileLayer, Marker } from 'react-leaflet'
import '../../app.css'
import { carbonEmissionNumberFormat, numberToAbbreviated } from 'utils/formatNumber'
// The Rightbar of the results section, i.e. the calculation results part

// Container for all the number cards
const NumberCardsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: scratch;
    height: 100%;
    box-sizing: border-box;
    gap: ${(props) => props.theme.spacing.m};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        gap: ${(props) => props.theme.spacing.s};
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        flex-direction: column;
    }
`

// Wrapper for each number card
const NumberCard = styled(Card)`
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: auto;
    box-sizing: border-box;
    gap: ${(props) => props.theme.spacing.s};
    padding: ${(props) => props.theme.spacing.m};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        width: 324px;
        height: 100%;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        width: 100%;
    }
`

// Wrapper for the data of the number cards
const DataWrapper = styled.div`
    font-family: ${(props) => props.theme.fonts.manropeSemiBold};
    font-size: 40px;
    line-height: 48px;
`

// Wrapper for the data of the number cards
const NoDataWrapper = styled.div`
    font-family: ${(props) => props.theme.fonts.manropeSemiBold};
    font-size: 23px;
    line-height: 48px;
    max-width: 100%;
    overflow-wrap: break-word;
    @media (max-width: 1200px) {
        line-height: 25px;
    }
`

// Wrapper for the black text in larger font size
const TextWrapper = styled(Subheading24)`
    display: flex;
    flex-direction: row;
    gap: ${(props) => props.theme.spacing.xxs};
    color: ${(props) => props.theme.primaryColor.black[100]};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        font-family: ${(props) => props.theme.typography.subheading20.fontFamily};
        font-size: ${(props) => props.theme.typography.subheading20.size};
        font-weight: ${(props) => props.theme.typography.subheading20.weight};
        line-height: ${(props) => props.theme.typography.subheading20.lineheight};
        letter-spacing: ${(props) => props.theme.typography.subheading20.letterSpacing};
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        font-family: ${(props) => props.theme.typography.subheading20.fontFamily};
        font-size: ${(props) => props.theme.typography.subheading20.size};
        font-weight: ${(props) => props.theme.typography.subheading20.weight};
        line-height: ${(props) => props.theme.typography.subheading20.lineheight};
        letter-spacing: ${(props) => props.theme.typography.subheading20.letterSpacing};
    }
`

// Wrapper for the map card
const MapCard = styled(Card)`
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
    gap: ${(props) => props.theme.spacing.s};
    padding: ${(props) => props.theme.spacing.m};
`
// Wrapper for the intro sentence of the map card
const IntroWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        gap: ${(props) => props.theme.spacing.s};
        flex-direction: column;
    }
`
// Wrapper for the mileage data of the map card
const MileWrapper = styled(Subheading24)`
    color: ${(props) => props.theme.primaryColor.lake[75]};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        font-family: ${(props) => props.theme.typography.subheading20.fontFamily};
        font-size: ${(props) => props.theme.typography.subheading20.size};
        font-weight: ${(props) => props.theme.typography.subheading20.weight};
        line-height: ${(props) => props.theme.typography.subheading20.lineheight};
        letter-spacing: ${(props) => props.theme.typography.subheading20.letterSpacing};
    }
`

// Wrapper for the map object that will disappear when the page is in tablet or mobile screen size
const MapWrapperDesktop = styled(MapContainer)`
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        display: none;
    }
`

// Wrapper for the map object that will appear when the page is in tablet screen size, and will disappear in mobile screen size
const MapWrapperTablet = styled(MapContainer)`
    @media (min-width: 769px) {
        display: none;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        display: none;
    }
`

// Wrapper for the map object that will appear when the page is in mobile screen size
const MapWrapperMobile = styled(MapContainer)`
    @media (min-width: ${(props) => props.theme.breakpoints.mobile}) {
        display: none;
    }
`
// Container for all the cards of equivalent values
const EquivalentCardsContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: scratch;
    justify-content: center;
    width: 100%;
    gap: ${(props) => props.theme.spacing.m};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        width: 100%;
        gap: ${(props) => props.theme.spacing.s};
    }
`

// Wrapper for each card of equivalent values
const EquivalentCard = styled(Card)`
    width: 266.6px;
    display: flex;
    height: auto;
    flex-direction: column;
    box-sizing: border-box;
    align-items: start;
    padding: ${(props) => props.theme.spacing.m};
    gap: ${(props) => props.theme.spacing.s};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        width: 208px;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        width: 100%;
    }
    img {
        width: 96px;
    }
`

const RightBar = (props: any) => (
    <>
        <NumberCardsContainer>
            <NumberCard>
                {props.data.carbonProducedAnnually != undefined && (
                    <DataWrapper style={{ color: '#9e712e' }}>
                        {carbonEmissionNumberFormat(props.data.carbonProducedAnnually / 1000)}
                    </DataWrapper>
                )}
                <TextWrapper>of C02 in one year</TextWrapper>
            </NumberCard>
            <NumberCard>
                {props.data.renewableEnergyPercent != null ? (
                    <DataWrapper style={{ color: '#5177b8' }}>
                        {Number(((props.data.renewableEnergyPercent / 100) * 5).toFixed(1))}/5
                    </DataWrapper>
                ) : (
                    <>
                        <NoDataWrapper style={{ color: '#c5c5c5' }}>Unavailable for this region</NoDataWrapper>
                    </>
                )}
                <TextWrapper>
                    Clean energy rating
                    <Pointer
                        direction={'down'}
                        message={
                            'We calculate this rating based on the type(s) of energy powering the data centers your site uses.'
                        }
                    >
                        <img src={InfoIcon} alt='logo' />
                    </Pointer>
                </TextWrapper>
            </NumberCard>
        </NumberCardsContainer>
        {(props.data.source_latitude != undefined || props.data.source_latitude != null) &&
            (props.data.source_longitude != undefined || props.data.source_longitude != null) &&
            (props.data.dest_latitude != undefined || props.data.dest_latitude != null) &&
            (props.data.dest_longitude != undefined || props.data.dest_longitude != null) && (
                <MapCard>
                    <IntroWrapper>
                        <TextWrapper>
                            Data Origin and Destination
                            {/* <Pointer
                        direction={'down'}
                        message={
                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
                        }
                    >
                        <img src={InfoIcon} alt='logo' />
                    </Pointer> */}
                        </TextWrapper>
                        <MileWrapper>{props.data.distanceInMiles} miles</MileWrapper>
                    </IntroWrapper>
                    <Paragraph16>The distance your data travels to reach its destination.</Paragraph16>
                    <MapWrapperDesktop
                        center={[props.data.dest_latitude, props.data.dest_longitude]}
                        zoom={2}
                        minZoom={1}
                        zoomSnap={0.05}
                        zoomControl={true}
                        dragging={true}
                    >
                        <TileLayer
                            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            noWrap={false}
                        />
                        <Marker position={[props.data.source_latitude, props.data.source_longitude]} />
                        <Marker position={[props.data.dest_latitude, props.data.dest_longitude]} />
                    </MapWrapperDesktop>
                    <MapWrapperTablet
                        center={[props.data.dest_latitude, props.data.dest_longitude]}
                        zoom={2}
                        minZoom={1}
                        zoomSnap={0.05}
                        zoomControl={true}
                        dragging={true}
                    >
                        <TileLayer
                            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            noWrap={false}
                        />
                        <Marker position={[props.data.source_latitude, props.data.source_longitude]} />
                        <Marker position={[props.data.dest_latitude, props.data.dest_longitude]} />
                    </MapWrapperTablet>
                    <MapWrapperMobile
                        center={[props.data.dest_latitude, props.data.dest_longitude]}
                        zoom={0}
                        minZoom={0}
                        zoomControl={true}
                        dragging={true}
                    >
                        <TileLayer
                            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            noWrap={false}
                        />
                        <Marker position={[props.data.source_latitude, props.data.source_longitude]} />
                        <Marker position={[props.data.dest_latitude, props.data.dest_longitude]} />
                    </MapWrapperMobile>
                </MapCard>
            )}
        {props.data.tree != undefined && props.data.energyConsumedAnnually != undefined && props.data.car != undefined && (
            <EquivalentCardsContainer>
                <EquivalentCard>
                    <img src={bulb} alt='bulb' />
                    <TextWrapper>{numberToAbbreviated(props.data.energyConsumedAnnually, true, false)} kWh</TextWrapper>
                    <Paragraph16>
                        This web page uses {numberToAbbreviated(props.data.energyConsumedAnnually, true, true)} kwh of
                        electricity annually.
                    </Paragraph16>
                </EquivalentCard>
                <EquivalentCard>
                    <img src={tree} alt='bulb' />
                    <TextWrapper>{numberToAbbreviated(props.data.tree, false, false)} trees</TextWrapper>
                    <Paragraph16>
                        It takes {numberToAbbreviated(props.data.tree, false, true)} trees a whole year to absorb the
                        CO2 produced by this single web page.
                    </Paragraph16>
                </EquivalentCard>
                <EquivalentCard>
                    <img src={car} alt='bulb' />
                    <TextWrapper>{numberToAbbreviated(props.data.car, false, false)} cars</TextWrapper>
                    <Paragraph16>
                        This webpage has the same annual CO2 emissions as{' '}
                        {numberToAbbreviated(props.data.car, false, true)} car(s).
                    </Paragraph16>
                </EquivalentCard>
            </EquivalentCardsContainer>
        )}
    </>
)

export default RightBar
