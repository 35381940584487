import styled from '@emotion/styled'

// The Radio Button component (without labels)

export const RadioButton = styled.input`
    &[type='radio'] {
        appearance: none;
        position: relative;
        background-color: ${(props) => props.theme.primaryColor.cloud[10]};
        border: solid 2px ${(props) => props.theme.primaryColor.black[25]};
        height: 24px;
        width: 24px;
        border-radius: 100%;
        cursor: pointer;
        margin: 0px;
        &:hover {
            border-color: ${(props) => props.theme.primaryColor.black[50]};
        }
        &:checked {
            background-color: ${(props) => props.theme.primaryColor.white};
            border: solid 8px ${(props) => props.theme.primaryColor.black[100]};
        }
        &:checked:hover {
            border: solid 8px ${(props) => props.theme.primaryColor.black[50]};
        }
    }
`
