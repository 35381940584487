import { Routes, Route } from 'react-router-dom'
import Home from './Home'
import About from './About'
import Calculator from './Calculator'
import HowItWorks from './HowItWorks'
import Corporate from './Corporate'
import Navbar from 'components/navbar'
import Error404 from './Error404'
import PrivacyPolicy from './PrivacyPolicy'
import TermsOfService from './TermsOfService'
import Pricing from './Pricing'
import PrivacyRelationship from './PrivacyRelationship'
import ThirdPartyLicenses from './ThirdPartyLicenses'
import SignUp from './SignUp'

const Main = () => (
    <Routes>
        <Route
            path='/'
            element={
                <>
                    <Navbar />
                    <Home />
                </>
            }
        />
        {/* <Route
            path='/contact'
            element={
                <>
                    <Navbar />
                    <Contact />
                </>
            }
        /> */}
        <Route
            path='/about'
            element={
                <>
                    <Navbar />
                    <About />
                </>
            }
        />
        <Route
            path='/calculator'
            element={
                <>
                    <Navbar />
                    <Calculator />
                </>
            }
        />
        <Route
            path='/how-it-works'
            element={
                <>
                    <Navbar />
                    <HowItWorks />
                </>
            }
        />
        {/* <Route
            path='/corporate'
            element={
                <>
                    <Navbar />
                    <Corporate />
                </>
            }
        /> */}
        <Route
            path='/calculator/:reportId'
            element={
                <>
                    <Navbar />
                    <Calculator />
                </>
            }
        />
        <Route
            path='/error404'
            element={
                <>
                    <Navbar />
                    <Error404 />
                </>
            }
        />
        <Route
            path='/pricing'
            element={
                <>
                    <Navbar />
                    <Pricing />
                </>
            }
        />
        <Route
            path='/privacy-policy'
            element={
                <>
                    <Navbar />
                    <PrivacyPolicy />
                </>
            }
        />
        <Route
            path='/terms-of-service'
            element={
                <>
                    <Navbar />
                    <TermsOfService />
                </>
            }
        />
        <Route
            path='/privacy/relationships'
            element={
                <>
                    <Navbar />
                    <PrivacyRelationship />
                </>
            }
        />
        <Route
            path='/resources/third-party-licenses'
            element={
                <>
                    <Navbar />
                    <ThirdPartyLicenses />
                </>
            }
        />
        {/* <Route
            path='/someonelikeyou'
            element={
                <>
                    <Navbar />
                    <SomeoneLikeYou />
                </>
            }
        /> */}
        <Route
            path='/signup'
            element={
                <>
                    <Navbar />
                    <SignUp />
                </>
            }
        />
    </Routes>
)
export default Main
