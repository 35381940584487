import styled from '@emotion/styled'
import { PlainLink } from 'components/atoms/link'
import { Paragraph18, HeadingsH1 } from 'styles/typography'

// styling for StyledDiv
const StyledDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 744px;
    background-color: ${(props) => props.theme.primaryColor.cloud[10]};
`
// Span that contains text after form is submitted
const SubmittedSpan = styled.span`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 416px;
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        width: 100%;
        margin-left: ${(props) => props.theme.spacing.s};
        margin-right: ${(props) => props.theme.spacing.s};
    }
`
// Styled paragraph for text displayed when the form is submitted
const SubmittedParagraph = styled(Paragraph18)`
    color: ${(props) => props.theme.primaryColor.black[75]};
    text-align: center;
    margin-top: ${(props) => props.theme.spacing.m};
`

// Media query styling for ContactHeading
const ContactHeading = styled(HeadingsH1)`
    color: ${(props) => props.theme.primaryColor.black[75]};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        text-align: center;
        font-family: ${(props) => props.theme.typography.headingsH2.fontFamily};
        font-size: ${(props) => props.theme.typography.headingsH2.size};
        font-weight: ${(props) => props.theme.typography.headingsH2.weight};
        line-height: ${(props) => props.theme.typography.headingsH2.lineheight};
        letter-spacing: ${(props) => props.theme.typography.headingsH2.letterSpacing};
    }
`

const ThankYou = () => (
    <StyledDiv>
        <SubmittedSpan>
            <>
                <ContactHeading>Thank you!</ContactHeading>
                <SubmittedParagraph>
                    Keep an eye on your inbox for an email from{' '}
                    <PlainLink to='/someonelikeyou'>support@digitalscope.com</PlainLink>, we’ll send an SDK key and
                    instructions for how to install it on your website.
                </SubmittedParagraph>
            </>
        </SubmittedSpan>
    </StyledDiv>
)

export default ThankYou
