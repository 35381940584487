import styled from '@emotion/styled'
import { DefaultLabel } from './label'
import { css } from '@emotion/react'

// Input styling for DefaultInputBox and DefaultTextarea
const inputStyling = (props: any) => css`
    box-sizing: border-box;
    margin-top: ${props.theme.spacing.xxs};
    padding: 6px ${props.theme.spacing.xs} 6px ${props.theme.spacing.xs};
    font-family: ${props.theme.typography.paragraph18.fontFamily};
    font-size: ${props.theme.typography.paragraph18.size};
    font-weight: ${props.theme.typography.paragraph18.weight};
    line-height: ${props.theme.typography.paragraph18.lineheight};
    letter-spacing: ${props.theme.typography.paragraph18.letterSpacing};
    border: solid ${props.theme.input.border} ${props.theme.primaryColor.black[25]};
    &::placeholder {
        color: ${props.theme.primaryColor.black[50]};
    }
    &.success {
        border: solid ${props.theme.input.border} ${props.theme.primaryColor.pine[75]};
    }
    &.error {
        border: solid ${props.theme.input.border} ${props.theme.primaryColor.arbutus[50]};
    }
    &:focus {
        outline: none;
        border: solid ${props.theme.input.border} ${props.theme.primaryColor.lake[25]};
    }
`
// Styling for DefaultInputBox
export const DefaultInputBox = styled.input`
    ${inputStyling}
`
// Input box that is styled large when the viewport is desktop sized or larger. Otherwise displays default styling.
export const MediaInputBox = styled.input`
    ${inputStyling}
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    ::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    @media (min-width: ${(props) => props.theme.breakpoints.tablet}) {
        font-family: ${(props) => props.theme.typography.formLabel.fontFamily};
        font-size: ${(props) => props.theme.typography.formLabel.size};
        line-height: ${(props) => props.theme.typography.formLabel.lineheight};
        padding-top: ${(props) => props.theme.input.paddingLarge};
        padding-bottom: ${(props) => props.theme.input.paddingLarge};
    }
`
// Styling for DefaultTextarea
export const DefaultTextarea = styled.textarea`
    ${inputStyling}
    resize: none;
    height: 144px;
`
// Wrapper that is placed around the input label, input box, and ErrorText component
export const InputWrapper = styled.span`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
`
/*
 * Component that contains error text and provides space between components
 * Display error styling by passing stateType prop as 'error'
 * Display error message by passing your text through the message prop
 */
export const ErrorText = (props: any) => {
    let errorMessage
    if (props.stateType === 'error') {
        errorMessage = <ErrorLabel>{props.message}</ErrorLabel>
    }
    return <ErrorWrapper>{errorMessage}</ErrorWrapper>
}

// Error label used in ErrorText that is only shown when stateType is equal to 'error'
const ErrorLabel = styled(DefaultLabel)`
    display: flex;
    justify-content: flex-end;
    margin-top: ${(props) => props.theme.spacing.xxs};
    color: ${(props) => props.theme.primaryColor.lake[25]};
    text-align: right;
`
// Wrapper used in ErrorText that contains ErrorLabel and provides space between components
const ErrorWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    height: ${(props) => props.theme.spacing.m};
`
