import styled from '@emotion/styled'
import { useState } from 'react'

/*
 * Div for the target object
 */
const ToolTipItem = styled.div`
    position: relative;
    display: inline-block;
`
/*
 * Styling for text
 */
const ToolTipText = styled.div`
    margin: ${(props) => props.theme.spacing.xxs} ${(props) => props.theme.spacing.xs};
    font-family: ${(props) => props.theme.fonts.manropeSemiBold};
    font-size: ${(props) => props.theme.typography.paragraph14.size};
    line-height: ${(props) => props.theme.tooltip.typography.lineheight};
    color: ${(props) => props.theme.primaryColor.white};
`
/*
 * Tooltip Container that has several classes for the directions of pointer
 * Can be referenced through direction prop
 * Space between object and tooltip is 8px (xxs) and height of pointer is 12px,
 * Margin of tootip = 8px + 12px = 20px
 * Placement of top & down pointer is 112px which is (240px(body-width)/2)-8px(pointer-side-width) = 112px
 */
const StyledToolTipContainer = styled.span`
    position: absolute;
    width: 240px;
    display: flex;
    align-self: stretch;
    justify-content: center;
    background-color: ${(props) => props.theme.primaryColor.black[100]};
    border-radius: ${(props) => props.theme.tooltip.style.radius};
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
    &.down {
        margin-bottom: 20px;
        bottom: 100%;
        right: 50%;
        transform: translateX(50%);
        &::after {
            position: absolute;
            content: '';
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 12px solid ${(props) => props.theme.primaryColor.black[100]};
            filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.2));
            bottom: -12px;
            left: 112px;
        }
    }
    &.up {
        margin-top: 20px;
        top: 100%;
        right: 50%;
        transform: translateX(50%);
        &::after {
            position: absolute;
            content: '';
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 12px solid ${(props) => props.theme.primaryColor.black[100]};
            filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.2));
            top: -12px;
            left: 112px;
        }
    }
    &.left {
        width: 228px;
        margin-left: 20px;
        left: 100%;
        top: 50%;
        transform: translateY(-50%);
        &::after {
            position: absolute;
            content: '';
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
            border-right: 12px solid ${(props) => props.theme.primaryColor.black[100]};
            filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.2));
            left: -12px;
            margin-top: -8px;
            margin-bottom: -8px;
            top: 50%;
            bottom: 50%;
        }
    }
    &.right {
        width: 228px;
        margin-right: 20px;
        right: 100%;
        top: 50%;
        transform: translateY(-50%);
        &::after {
            position: absolute;
            content: '';
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
            border-left: 12px solid ${(props) => props.theme.primaryColor.black[100]};
            filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.2));
            right: -12px;
            margin-top: -8px;
            margin-bottom: -8px;
            top: 50%;
            bottom: 50%;
        }
    }
`

export const Pointer = (props: any) => {
    const [isHovered, setIsHovered] = useState(false)
    const showToolTip = isHovered
    return (
        <ToolTipItem>
            <ToolTipItem onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                {props.children}
            </ToolTipItem>
            {showToolTip && (
                <StyledToolTipContainer className={props.direction}>
                    <ToolTipText>{props.message}</ToolTipText>
                </StyledToolTipContainer>
            )}
        </ToolTipItem>
    )
}
