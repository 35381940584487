import styled from '@emotion/styled'

/*
Card componnent
Adjust size to use
*/
export const Card = styled.div`
    width: ${(props) => props.theme.width.button};
    height: ${(props) => props.theme.width.button};
    background-color: ${(props) => props.theme.primaryColor.white};
    border-radius: ${(props) => props.theme.spacing.wide};
    box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.05);
`
