import { useLottieInteractivity, useLottie } from 'lottie-react'
import sdk from '../../assets/animations/sdk.json'
import map from '../../assets/animations/map.json'

/*
Atom that holds the animation components
*/

//sdk animation
export const sdkAnimationObject = {
    animationData: sdk,
}

export const SdkAnimation = () => {
    const lottieObj = useLottie(sdkAnimationObject)
    const animation = useLottieInteractivity({
        lottieObj,
        mode: 'scroll',
        actions: [
            {
                visibility: [0, 1],
                type: 'play',
                frames: [0],
            },
        ],
    })
    return animation
}

//map animation
export const mapAnimationObject = {
    animationData: map,
}

export const MapAnimation = () => {
    const lottieObj = useLottie(mapAnimationObject)
    const animation = useLottieInteractivity({
        lottieObj,
        mode: 'scroll',
        actions: [
            {
                visibility: [0, 1],
                type: 'play',
                frames: [0],
            },
        ],
    })
    return animation
}
