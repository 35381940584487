import styled from '@emotion/styled'
import { SmallBlackButton } from 'components/atoms/button'
import { HeaderLink } from 'components/atoms/link'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import closeIcon from '../assets/icons/close.svg'
import blackLogo from '../assets/icons/logo-black.svg'
import menuIcon from '../assets/icons/menu.svg'

// Wrapper for page links
const LinkWrapper = styled.div`
    display: flex;
`
// Styling for logo
const StyledImg = styled.img`
    width: ${(props) => props.theme.width.logo};
`
// Menu that only appears when the page is tablet/mobile screen size
const StyledMenuIcon = styled.img`
    display: flex;
    padding-bottom: 3px;
    margin-left: 35px;
    @media (min-width: 769px) {
        display: none;
    }
`
// Link with no styling that disappears when the page is mobile screen size
export const SimpleLink = styled(Link)`
    text-decoration: none;
    &.hideMobile {
        @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
            display: none;
        }
    }
`
// Styling for StyledLink that disappears at different screen sizes if className is provided in the tag
const StyledNavLink = styled(HeaderLink)`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: ${(props) => props.theme.spacing.m};
    &.hideTablet {
        @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
            display: none;
        }
    }
    &.hideMobile {
        @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
            display: none;
        }
    }
`
// Styling for the MobileNavLink
const MobileNavLink = styled(StyledNavLink)`
    text-decoration: none;
    margin-bottom: ${(props) => props.theme.spacing.m};
    margin-right: 0px;
`

// Styling for the TabletNavLink
const TabletNavLink = styled(StyledNavLink)`
    text-decoration: none;
    margin-bottom: ${(props) => props.theme.spacing.m};
    margin-right: 0px;
    @media (min-width: ${(props) => props.theme.breakpoints.mobile}) {
        display: none;
    }
`
// Styling for NavWrapper
const NavWrapper = styled.nav`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${(props) => props.theme.primaryColor.white};
    padding-left: ${(props) => props.theme.spacing.xxl.default};
    padding-right: ${(props) => props.theme.spacing.xxl.default};
    padding-top: ${(props) => props.theme.spacing.xs};
    padding-bottom: ${(props) => props.theme.spacing.xs};
    position: sticky;
    top: 0;
    z-index: 10;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        padding-left: ${(props) => props.theme.spacing.l};
        padding-right: ${(props) => props.theme.spacing.l};
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        padding-left: ${(props) => props.theme.spacing.s};
        padding-right: ${(props) => props.theme.spacing.s};
    }
`
// Div container for the mobile menu
const OpenMenu = styled.div`
    display: flex;
    background-color: white;
    flex-direction: column;
    align-items: center;
    z-index: 10;
    height: 90vh;
    width: 100%;
    overflow-y: hidden;
    @media (min-width: 769px) {
        display: none;
    }
`
// Container that makes all of it's contents stick to the top of the viewport
const StickyContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
    z-index: 10;
`

// Custom small black button for navbar
const CustomSmallBlackButton = styled(SmallBlackButton)`
    width: 115px;
`

//constant to scroll page to top
export const goToTop = () => {
    window.scrollTo({
        top: 0,
    })
}

// Navigation bar
const Navbar = () => {
    let menu
    const [showMenu, setShowMenu] = useState(false)

    const handleClick = () => {
        setShowMenu(false)
        goToTop()
    }

    // Mobile menu that displays when the StyledMenuIcon is clicked
    if (showMenu) {
        menu = (
            <OpenMenu>
                <TabletNavLink to='/calculator' onClick={() => setShowMenu(false)}>
                    Calculator
                </TabletNavLink>
                {/* <MobileNavLink to='/corporate' onClick={() => setShowMenu(false)}>
                    Corporate
                </MobileNavLink> */}
                <MobileNavLink to='/how-it-works' onClick={() => setShowMenu(false)}>
                    How it works
                </MobileNavLink>
                <MobileNavLink to='/about' onClick={() => setShowMenu(false)}>
                    About us
                </MobileNavLink>
                <MobileNavLink to='/pricing' onClick={() => setShowMenu(false)}>
                    Pricing
                </MobileNavLink>
                <a href='https://dashboard.digitalscope.com/signin'>
                    <CustomSmallBlackButton>Sign In</CustomSmallBlackButton>
                </a>
            </OpenMenu>
        )
    }

    // Rendered navigation bar
    return (
        <StickyContainer>
            <NavWrapper>
                <SimpleLink to='/' onClick={handleClick}>
                    <StyledImg src={blackLogo} alt='logo' />
                </SimpleLink>
                <LinkWrapper>
                    <StyledNavLink to='/calculator' className='hideMobile' onClick={window.location.reload}>
                        Calculator
                    </StyledNavLink>
                    {/* <StyledNavLink to='/corporate' className='hideTablet' onClick={goToTop}>
                        Corporate
                    </StyledNavLink> */}
                    <StyledNavLink to='/how-it-works' className='hideTablet' onClick={goToTop}>
                        How it Works
                    </StyledNavLink>
                    <StyledNavLink to='/about' className='hideTablet' onClick={goToTop}>
                        About us
                    </StyledNavLink>
                    <StyledNavLink to='/pricing' className='hideTablet' onClick={goToTop}>
                        Pricing
                    </StyledNavLink>
                    <a href='https://dashboard.digitalscope.com/signin'>
                        <CustomSmallBlackButton>Sign In</CustomSmallBlackButton>
                    </a>
                    <StyledMenuIcon
                        src={showMenu ? closeIcon : menuIcon}
                        alt='menu'
                        onClick={() => setShowMenu(!showMenu)}
                    />
                </LinkWrapper>
            </NavWrapper>
            {menu}
        </StickyContainer>
    )
}

export default Navbar
