import styled from '@emotion/styled'
import { HeadingsH1 } from 'styles/typography'
import Iceberg from '../assets/animations/iceberg.json'
import { useLottie, useLottieInteractivity } from 'lottie-react'

// About Page

// Container for the whole section
const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.primaryColor.cloud[10]};
    padding: ${(props) => props.theme.spacing.xl} 176px;
    gap: ${(props) => props.theme.spacing.xxl.default};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        padding: ${(props) => props.theme.spacing.xxl.default} ${(props) => props.theme.spacing.l};
        gap: ${(props) => props.theme.spacing.l};
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        padding-left: ${(props) => props.theme.spacing.s};
        padding-right: ${(props) => props.theme.spacing.s};
    }
`
// Wrapper for the heading
const HeadingWarpper = styled(HeadingsH1)`
    text-align: center;
    div {
        color: ${(props) => props.theme.primaryColor.lake[75]};
        margin-bottom: ${(props) => props.theme.spacing.m};
        font-family: ${(props) => props.theme.typography.allCaps16.fontFamily};
        font-size: ${(props) => props.theme.typography.allCaps16.size};
        font-weight: ${(props) => props.theme.typography.allCaps16.weight};
        line-height: ${(props) => props.theme.typography.allCaps16.lineheight};
        letter-spacing: ${(props) => props.theme.typography.allCaps16.letterSpacing};
    }
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        font-family: ${(props) => props.theme.typography.headingsH2.fontFamily};
        font-size: ${(props) => props.theme.typography.headingsH2.size};
        font-weight: ${(props) => props.theme.typography.headingsH2.weight};
        line-height: ${(props) => props.theme.typography.headingsH2.lineheight};
        letter-spacing: ${(props) => props.theme.typography.headingsH2.letterSpacing};
    }
`

// Wrapper for the intro paragraph
const IntroWarpper = styled.div`
    width: 100%;
    max-width: 1088px;
    border-top: ${(props) => props.theme.input.border} solid ${(props) => props.theme.primaryColor.black[100]};
    border-bottom: ${(props) => props.theme.input.border} solid ${(props) => props.theme.primaryColor.black[100]};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        width: unset;
    }
    div {
        text-align: center;
        color: ${(props) => props.theme.primaryColor.lake[75]};
        font-family: ${(props) => props.theme.typography.subheading20.fontFamily};
        font-size: ${(props) => props.theme.typography.subheading20.size};
        font-weight: ${(props) => props.theme.typography.subheading20.weight};
        line-height: ${(props) => props.theme.typography.subheading20.lineheight};
        letter-spacing: ${(props) => props.theme.typography.subheading20.letterSpacing};
        margin: 34px ${(props) => props.theme.spacing.l};
        @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
            margin: 34px 0px;
        }
    }
`
// Container for the image and paragraph
const BodyContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${(props) => props.theme.spacing.xxl.default};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        gap: ${(props) => props.theme.spacing.l};
        flex-direction: column;
    }
`
// Wrapper for the image
const ImageWrapper = styled.div`
    img {
        width: 528px;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        img {
            width: 311px;
        }
    }
`
// Warpper for the paragraph
const ParagraphWrapper = styled.div`
    width: 496px;
    color: ${(props) => props.theme.primaryColor.black[75]};
    font-family: ${(props) => props.theme.typography.paragraph18.fontFamily};
    font-size: ${(props) => props.theme.typography.paragraph18.size};
    font-weight: ${(props) => props.theme.typography.paragraph18.weight};
    line-height: ${(props) => props.theme.typography.paragraph18.lineheight};
    letter-spacing: ${(props) => props.theme.typography.paragraph18.letterSpacing};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        width: unset;
    }
`

// Configure the properties of the iceberg animation so it will only start when the user has scrolled down to where it’s in view
const options = {
    animationData: Iceberg,
}

const IcebergAnimation = () => {
    const lottieObj = useLottie(options)
    const Animation = useLottieInteractivity({
        lottieObj,
        mode: 'scroll',
        actions: [
            {
                visibility: [0, 1],
                type: 'play',
                frames: [0],
            },
        ],
    })
    return Animation
}

const About = () => (
    <Container>
        <HeadingWarpper>
            <div>UNDERSTAND</div>
            Measuring your digital carbon footprint
        </HeadingWarpper>
        <IntroWarpper>
            <div>For most companies, Scope 3 emissions represent more than 65% of their carbon footprint.</div>
        </IntroWarpper>
        <BodyContainer>
            <ParagraphWrapper>
                Powering digital devices, data centers, and network infrastructure requires a lot of energy. When that
                electricity isn’t green, it puts the demand on power sources that are huge producers of CO2, like fossil
                fuels.
                <p />
                With potentially thousands of monthly visitors across software products, platforms, or websites, your
                business is growing its Scope 1-3 emissions. But by better understanding your digital carbon footprint,
                you can take responsibility for every byte of data that travels between you and your consumers.
                <p />
                Through Digital Scope, you can use our free tool to calculate how much CO2 your digital assets create –
                and set the foundation for accurate carbon action.
            </ParagraphWrapper>
            <ImageWrapper>
                <IcebergAnimation />
            </ImageWrapper>
        </BodyContainer>
    </Container>
)

export default About
