import styled from '@emotion/styled'
import { BigWhiteButton } from 'components/atoms/button'
import { Link } from 'react-router-dom'
import { Paragraph18 } from 'styles/typography'

//wrapper for indexing
const PageWrapper = styled.div`
    position: relative;
    background-color: ${(props) => props.theme.primaryColor.lake[75]};
`
//wrapper for flex box
const ContentWrapper = styled.div`
    display: flex;
    justify-content: center;
`
//wrapper for content margin
const TextContainer = styled.div`
    padding: ${(props) => props.theme.spacing.s} 0px;
    display: flex;
    flex-direction: row;
    position: absoulute;
    z-index: 5;
    align-items: center;
    justify-content: space-between;
    gap: ${(props) => props.theme.spacing.gap};
    text-align: center;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        padding: ${(props) => props.theme.spacing.s} ${(props) => props.theme.spacing.s};
        width: 100%;
        flex-direction: column;
        gap: ${(props) => props.theme.spacing.xs};
    }
`
//constant to scroll page to top
export const goToTop = () => {
    window.scrollTo({
        top: 0,
    })
}

export const SimpleLink = styled(Link)`
    text-decoration: none;
    &.hideMobile {
        @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
            display: none;
        }
    }
`

//wrapper for button sizing
const CalculateWhiteButton = styled(BigWhiteButton)`
    padding: ${(props) => props.theme.spacing.xxs} ${(props) => props.theme.spacing.s};
    height: auto;
    width: auto;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        width: ${(props) => props.theme.home.width.tabletButton};
        height: ${(props) => props.theme.home.height.tabletButton};
        font-size: ${(props) => props.theme.typography.allCaps14.size};
        font-weight: ${(props) => props.theme.typography.allCaps14.weight};
        line-height: ${(props) => props.theme.typography.allCaps14.lineheight};
        letter-spacing: ${(props) => props.theme.typography.allCaps14.letterSpacing};
    }
`

export const CalculateMessage = () => (
    <PageWrapper>
        <ContentWrapper>
            <TextContainer>
                <Paragraph18 style={{ color: '#FFF', fontSize: '20px' }}>
                    Curious about your website’s carbon emissions?
                </Paragraph18>
                <SimpleLink to='/calculator' onClick={goToTop}>
                    <CalculateWhiteButton>Calculate Now</CalculateWhiteButton>
                </SimpleLink>
            </TextContainer>
        </ContentWrapper>
    </PageWrapper>
)
