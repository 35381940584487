import styled from '@emotion/styled'
import leftIcon from '../../assets/icons/Icon-right-half.svg'
import rightIcon from '../../assets/icons/Icon-left-half.svg'

//wrapper to set the background color and size
const PageWrapper = styled.div`
    background-color: ${(props) => props.theme.primaryColor.black[100]};
    position: absolute;
    width: 100%;
    z-index: 1;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        height: ${(props) => props.theme.home.height.tablet};
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        height: ${(props) => props.theme.home.height.mobile};
    }
`
//wrapper for flex boxes
const HomeWrapper = styled.div`
    height: ${(props) => props.theme.home.height.desktop};
    display: flex;
    position: relative;
    justify-content: space-between;
`
//wrapper for right-hand image margin
const RightImageWrapper = styled.img`
    width: ${(props) => props.theme.home.width.image};
    height: ${(props) => props.theme.home.height.image};
    display: flex;
    position: absolute;
    right: 0;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        margin-top: ${(props) => props.theme.home.margin.rightImage.tabletTop};
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        margin-top: ${(props) => props.theme.home.margin.rightImage.mobileTop};
    }
`
//wrapper for left-hand image margin
const LeftImageWrapper = styled.img`
    width: ${(props) => props.theme.home.width.image};
    height: ${(props) => props.theme.home.height.image};
    margin-top: ${(props) => props.theme.home.margin.leftImage.desktopTop};
    z-index: 3;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        margin-top: ${(props) => props.theme.home.margin.leftImage.tabletTop};
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        margin-top: ${(props) => props.theme.home.margin.leftImage.mobileTop};
    }
`
export const HomeBackground = () => (
    <PageWrapper>
        <HomeWrapper>
            <LeftImageWrapper src={leftIcon} alt='logo' />
            <RightImageWrapper src={rightIcon} alt='logo' />
        </HomeWrapper>
    </PageWrapper>
)
