import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

// Privacy Policy Page

const ContainerWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
`

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: ${(props) => props.theme.primaryColor.cloud[10]};
    padding: ${(props) => props.theme.spacing.xl} 176px;
    gap: ${(props) => props.theme.spacing.s};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        padding: ${(props) => props.theme.spacing.xxl.default} ${(props) => props.theme.spacing.l};
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        padding-left: ${(props) => props.theme.spacing.s};
        padding-right: ${(props) => props.theme.spacing.s};
    }
`

const ParagraphWrapper = styled.div`
    width: 100%;
    max-width: 1440px;
    color: ${(props) => props.theme.primaryColor.black[75]};
    font-family: ${(props) => props.theme.typography.paragraph18.fontFamily};
    font-size: ${(props) => props.theme.typography.paragraph18.size};
    font-weight: ${(props) => props.theme.typography.paragraph18.weight};
    line-height: ${(props) => props.theme.typography.paragraph18.lineheight};
    letter-spacing: ${(props) => props.theme.typography.paragraph18.letterSpacing};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        width: unset;
    }
    a {
        text-decoration: none;
        border-bottom: 2px solid #5177b8;
        font-family: ${(props) => props.theme.typography.paragraph18.fontFamily};
        font-size: ${(props) => props.theme.typography.paragraph18.size};
        font-weight: ${(props) => props.theme.typography.paragraph18.weight};
        line-height: ${(props) => props.theme.typography.paragraph18.lineheight};
        letter-spacing: ${(props) => props.theme.typography.paragraph18.letterSpacing};
        color: ${(props) => props.theme.primaryColor.black[75]};
        &:hover {
            color: #5177b8;
            text-decoration: none;
            border-bottom: 2px solid #5177b8;
        }
    }
`

// Link with no styling that disappears when the page is mobile screen size
export const PlainLink = styled(Link)`
    text-decoration: none;
    border-bottom: 2px solid #5177b8;
    font-family: ${(props) => props.theme.typography.paragraph18.fontFamily};
    font-size: ${(props) => props.theme.typography.paragraph18.size};
    font-weight: ${(props) => props.theme.typography.paragraph18.weight};
    line-height: ${(props) => props.theme.typography.paragraph18.lineheight};
    letter-spacing: ${(props) => props.theme.typography.paragraph18.letterSpacing};
    color: ${(props) => props.theme.primaryColor.black[75]};
    &:hover {
        color: #5177b8;
        text-decoration: none;
        border-bottom: 2px solid #5177b8;
    }
`
//constant to scroll page to top
export const goToTop = () => {
    window.scrollTo({
        top: 0,
    })
}

const PrivacyRelationship = () => (
    <ContainerWrapper>
        <Container>
            <ParagraphWrapper>
                UPDATED: OCTOBER 1 2022 <br /> <br />
                Redbrick may collect and store certain Personal Data when you access its Site(s) and/or use any
                Software, Services, Related Materials, and/or related products or services being made available from
                Redbrick. Any collection, storage, and use of Personal Data shall be in accordance with Redbrick’s
                Privacy Policy located at{' '}
                <PlainLink to='/privacy-policy' onClick={goToTop}>
                    digitalscope.com/privacy-policy.
                </PlainLink>
                <br /> <br />
                Redbrick has prepared this resource to list the various third-party technologies, software, services,
                and/or solutions providers with whom Redbrick may use or share Personal Data for legitimate and lawful
                purposes as permitted under applicable laws. <br /> <br />
                PLEASE NOTE THAT REDBRICK MAY UPDATE THIS RESOURCE AT ANY TIME WITHOUT PRIOR NOTICE TO YOU.
                <br />
                <ul>
                    <li>
                        Amazon Web Services (Hosting and Domain Services, Cloud Services);{' '}
                        <a href='https://aws.amazon.com/privacy/'> aws.amazon.com/privacy</a>
                    </li>
                    <li>
                        Godaddy (Hosting and Domain Services);{' '}
                        <a href='https://www.godaddy.com/en-ca/legal/agreements/privacy-policy'>
                            godaddy.com/en-ca/legal/agreements/privacy-policy
                        </a>
                    </li>
                    <li>
                        Google Adwords (Advertising Services);{' '}
                        <a href='https://policies.google.com/privacy'> policies.google.com/privacy</a>
                    </li>
                    <li>
                        Google Analytics (Traffic/Campaign Monitoring Services);{' '}
                        <a href='https://policies.google.com/privacy'> policies.google.com/privacy</a>
                    </li>
                    <li>
                        Google Optimize (Website Optimization Services);{' '}
                        <a href='https://policies.google.com/privacy'> policies.google.com/privacy</a>
                    </li>
                    <li>
                        Ipapi (Traffic Monitoring Services); <a href='https://ipapi.co/privacy/'> ipapi.co/privacy</a>
                    </li>
                    <li>
                        New Relic (Traffic Monitoring Services);{' '}
                        <a href='https://newrelic.com/privacy'> newrelic.com/privacy</a>
                    </li>
                </ul>
            </ParagraphWrapper>
        </Container>
    </ContainerWrapper>
)

export default PrivacyRelationship
