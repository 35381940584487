import { CalculatorInputSection } from 'components/calculator/calculatorInputSection'
import { useParams } from 'react-router-dom'

// Calculator Page
export default function Calculator() {
    const params = useParams()
    const reportId = params.reportId

    return (
        <>
            <CalculatorInputSection reportId={reportId ? reportId : ''} />
        </>
    )
}
