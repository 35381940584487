import styled from '@emotion/styled'
import { SmallBlackButton } from 'components/atoms/button'
import { HeadingsH1 } from 'styles/typography'
import { goToTop, SimpleLink } from 'components/navbar'
import { MapAnimation } from 'components/atoms/animations'

// How-it-works Page

// Container for the whole section
const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ${(props) => props.theme.spacing.xl} 176px;
    gap: ${(props) => props.theme.spacing.xxl.default};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        padding: ${(props) => props.theme.spacing.xxl.default} ${(props) => props.theme.spacing.l};
        gap: ${(props) => props.theme.spacing.l};
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        padding-left: ${(props) => props.theme.spacing.s};
        padding-right: ${(props) => props.theme.spacing.s};
    }
`
// Wrapper for the heading
const HeadingWarpper = styled(HeadingsH1)`
    text-align: center;
    div {
        color: ${(props) => props.theme.primaryColor.pine[75]};
        margin-bottom: ${(props) => props.theme.spacing.m};
        font-family: ${(props) => props.theme.typography.allCaps16.fontFamily};
        font-size: ${(props) => props.theme.typography.allCaps16.size};
        font-weight: ${(props) => props.theme.typography.allCaps16.weight};
        line-height: ${(props) => props.theme.typography.allCaps16.lineheight};
        letter-spacing: ${(props) => props.theme.typography.allCaps16.letterSpacing};
    }
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        font-family: ${(props) => props.theme.typography.headingsH2.fontFamily};
        font-size: ${(props) => props.theme.typography.headingsH2.size};
        font-weight: ${(props) => props.theme.typography.headingsH2.weight};
        line-height: ${(props) => props.theme.typography.headingsH2.lineheight};
        letter-spacing: ${(props) => props.theme.typography.headingsH2.letterSpacing};
    }
`
// Wrapper for the intro paragraph
const IntroWarpper = styled.div`
    width: 100%;
    max-width: 1088px;
    border-top: ${(props) => props.theme.input.border} solid ${(props) => props.theme.primaryColor.black[100]};
    border-bottom: ${(props) => props.theme.input.border} solid ${(props) => props.theme.primaryColor.black[100]};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        width: unset;
    }
    div {
        text-align: center;
        color: ${(props) => props.theme.primaryColor.pine[75]};
        font-family: ${(props) => props.theme.typography.subheading20.fontFamily};
        font-size: ${(props) => props.theme.typography.subheading20.size};
        font-weight: ${(props) => props.theme.typography.subheading20.weight};
        line-height: ${(props) => props.theme.typography.subheading20.lineheight};
        letter-spacing: ${(props) => props.theme.typography.subheading20.letterSpacing};
        margin: 34px ${(props) => props.theme.spacing.l};
        @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
            margin: 34px 0px;
        }
    }
`
// // Wrapper for the image
// const ImageWrapper = styled.div`
//     img {
//         width: 641.6px;
//     }
//     @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
//         img {
//             width: 640px;
//         }
//     }
//     @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
//         img {
//             width: 312px;
//         }
//     }
// `
// Warpper for the paragraph
const ParagraphWrapper = styled.div`
    width: 640px;
    color: ${(props) => props.theme.primaryColor.black[75]};
    font-family: ${(props) => props.theme.typography.paragraph18.fontFamily};
    font-size: ${(props) => props.theme.typography.paragraph18.size};
    font-weight: ${(props) => props.theme.typography.paragraph18.weight};
    line-height: ${(props) => props.theme.typography.paragraph18.lineheight};
    letter-spacing: ${(props) => props.theme.typography.paragraph18.letterSpacing};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        width: unset;
    }
`

const HowItWorks = () => (
    <Container>
        <HeadingWarpper>
            <div>MEASURE</div>
            Building a smarter algorithm
        </HeadingWarpper>
        <IntroWarpper>
            <div>Data centers consume 200 to 500 billion kWh a year, an estimated 3% of the world’s electricity.</div>
        </IntroWarpper>
        <MapAnimation />
        <ParagraphWrapper>
            The further data travels, the more power-intensive data centers it passes through. With each “hop” between
            servers, that byte of data is responsible for more electricity consumption — but existing tools don’t factor
            this into their calculations.
            <p />
            Our carbon measurement can trace which servers data passes through when fulfilling a page request. This
            generates a more accurate estimate of that web page’s power consumption. Plus, it allows us to factor in
            information like the clean energy rating of each data center.
            <p />
            We’re currently finalizing a formal research paper on our methodology, but you can find a technical overview
            of our project below.
        </ParagraphWrapper>
        {/* <SimpleLink to='/how-it-works' onClick={goToTop}>
            <SmallBlackButton style={{ width: '148px', padding: 'unset' }}>MORE DETAIL</SmallBlackButton>
        </SimpleLink> */}
        {/* TODO: uncomment once the paper is published */}
    </Container>
)

export default HowItWorks
