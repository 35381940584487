import styled from '@emotion/styled'

/**
 * Form label styling components
 */
export const DefaultLabel = styled.label`
    font-family: ${(props) => props.theme.typography.allCaps12.fontFamily};
    font-size: ${(props) => props.theme.typography.allCaps12.size};
    font-weight: ${(props) => props.theme.typography.allCaps12.weight};
    line-height: ${(props) => props.theme.typography.allCaps12.lineheight};
    letter-spacing: ${(props) => props.theme.typography.allCaps12.letterSpacing};
    text-transform: uppercase;
    &.required:after {
        content: '';
        display: inline-block;
        width: 4px;
        height: 4px;
        background: #cb974b;
        border-radius: 50%;
        margin-bottom: 9px;
    }
`

export const RequiredLabel = styled(DefaultLabel)`
    &:after {
        content: '';
        display: inline-block;
        width: 4px;
        height: 4px;
        background: #cb974b;
        border-radius: 50%;
        margin-bottom: 9px;
    }
`
