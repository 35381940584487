import styled from '@emotion/styled'
import { HeadingsH1 } from 'styles/typography'
import { Link } from 'react-router-dom'

// Privacy Policy Page

const ContainerWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
`

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: ${(props) => props.theme.primaryColor.cloud[10]};
    padding: ${(props) => props.theme.spacing.xl} 176px;
    gap: ${(props) => props.theme.spacing.s};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        padding: ${(props) => props.theme.spacing.xxl.default} ${(props) => props.theme.spacing.l};
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        padding-left: ${(props) => props.theme.spacing.s};
        padding-right: ${(props) => props.theme.spacing.s};
    }
`
// Wrapper for the heading
const HeadingWarpper = styled(HeadingsH1)`
    width: 100%;
    max-width: 1440px;
    font-size: ${(props) => props.theme.typography.headingsH2.size};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        font-family: ${(props) => props.theme.typography.headingsH2.fontFamily};
        font-size: ${(props) => props.theme.typography.headingsH2.size};
        font-weight: ${(props) => props.theme.typography.headingsH2.weight};
        line-height: ${(props) => props.theme.typography.headingsH2.lineheight};
        letter-spacing: ${(props) => props.theme.typography.headingsH2.letterSpacing};
    }
`

const ParagraphWrapper = styled.div`
    width: 100%;
    max-width: 1440px;
    color: ${(props) => props.theme.primaryColor.black[75]};
    font-family: ${(props) => props.theme.typography.paragraph18.fontFamily};
    font-size: ${(props) => props.theme.typography.paragraph18.size};
    font-weight: ${(props) => props.theme.typography.paragraph18.weight};
    line-height: ${(props) => props.theme.typography.paragraph18.lineheight};
    letter-spacing: ${(props) => props.theme.typography.paragraph18.letterSpacing};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        width: unset;
    }
    a {
        text-decoration: none;
        border-bottom: 2px solid #5177b8;
        font-family: ${(props) => props.theme.typography.paragraph18.fontFamily};
        font-size: ${(props) => props.theme.typography.paragraph18.size};
        font-weight: ${(props) => props.theme.typography.paragraph18.weight};
        line-height: ${(props) => props.theme.typography.paragraph18.lineheight};
        letter-spacing: ${(props) => props.theme.typography.paragraph18.letterSpacing};
        color: ${(props) => props.theme.primaryColor.black[75]};
        &:hover {
            color: #5177b8;
            text-decoration: none;
            border-bottom: 2px solid #5177b8;
        }
    }
`

// Link with no styling that disappears when the page is mobile screen size
export const PlainLink = styled(Link)``
//constant to scroll page to top
export const goToTop = () => {
    window.scrollTo({
        top: 0,
    })
}

const ThirdPartyLicenses = () => (
    <ContainerWrapper>
        <Container>
            <HeadingWarpper style={{ fontSize: '56px', display: 'flex', justifyContent: 'center' }}>
                Third-Party Open-Source Licenses
            </HeadingWarpper>
            <ParagraphWrapper>
                UPDATED: OCTOBER 1, 2022 <br /> <br />
                Redbrick has prepared this resource to list the various third-party open-source technologies that have
                been integrated with the Software, the Services, and/or any Related Materials. <br /> <br />
                THIS RESOURCE WILL BE UPDATED TO IDENTIFY CHANGES TO THIRD-PARTY OPEN-SOURCE TECHNOLOGIES THAT HAVE BEEN
                INTEGRATED WITH THE SOFTWARE, THE SERVICES, AND/OR ANY RELATED MATERIALS. <br />
                <ul>
                    <li>
                        Django: <a href='https://www.djangoproject.com/'> djangoproject.com</a>
                    </li>
                    <li>
                        Emotion JS: <a href='https://emotion.sh/docs/introduction'> emotion.sh/docs/introduction</a>
                    </li>
                    <li>
                        Geopy: <a href='https://pypi.org/project/geopy/'> pypi.org/project/geopy</a>
                    </li>
                    <li>
                        Icmplib: <a href='https://pypi.org/project/icmplib/'> pypi.org/project/icmplib</a>
                    </li>
                    <li>
                        Leaflet: <a href='hhttps://leafletjs.com/'> leafletjs.com</a>
                    </li>
                    <li>
                        Lottie: <a href='https://airbnb.io/lottie/#/'> airbnb.io/lottie</a>
                    </li>
                    <li>
                        React: <a href='https://reactjs.org'> reactjs.org</a>
                    </li>
                    <li>
                        Sendgrid:{' '}
                        <a href='https://github.com/sendgrid/sendgrid-python'> github.com/sendgrid/sendgrid-python</a>
                    </li>
                </ul>
            </ParagraphWrapper>
        </Container>
    </ContainerWrapper>
)

export default ThirdPartyLicenses
