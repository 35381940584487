// abbreviated function

export function numberToAbbreviated(value: any, kg: boolean, descryption: boolean): any {
    let newValue
    newValue = value
    const suffixesShort = ['', 'K', 'M', 'B', 'T', 'Qa', 'Qi', 'Sx', 'Sp']
    const suffixesLong = [
        '',
        ' thousand',
        ' million',
        ' billion',
        ' trillion',
        ' quadrillion',
        ' quintillion',
        ' sextillion',
        ' septillion',
    ]
    let suffixNum = 0
    while (newValue >= 1000) {
        newValue /= 1000
        suffixNum = suffixNum + 1
    }
    if (!Number.isInteger(newValue)) {
        if (parseInt(newValue) < 100) {
            newValue = newValue.toPrecision(3)
        } else {
            newValue = parseInt(newValue)
        }
    }

    if (suffixNum == 1 && kg) {
        newValue = parseInt(value).toLocaleString()
    } else {
        if (descryption) {
            newValue += suffixesLong[suffixNum]
        } else {
            newValue += suffixesShort[suffixNum]
        }
    }

    return newValue
}

export function carbonEmissionNumberFormat(value: any): any {
    let newValue
    newValue = value
    if (value < 1000) {
        newValue = value
    } else {
        newValue = value / 1000
    }
    const unit = [' kg', ' Tonne']

    if (value < 100) {
        newValue = newValue.toPrecision(3).toLocaleString()
        newValue += unit[0]
    } else if (value >= 100 && value < 1000) {
        newValue = parseInt(newValue).toLocaleString()
        newValue += unit[0]
    } else {
        newValue = numberToAbbreviated(newValue, false, false)
        newValue += unit[1]
    }

    return newValue
}
