import styled from '@emotion/styled'
import greenHosting from '../../assets/icons/greenhosting.svg'
import caches from '../../assets/icons/caches.svg'
import dataCentre from '../../assets/icons/datacentre.svg'
import audit from '../../assets/icons/audit.svg'
import { SmallBlackButton } from 'components/atoms/button'
import { Card } from 'components/atoms/card'
import { PlainLink } from 'components/atoms/link'
import { useNavigate } from 'react-router-dom'
import { HeadingsH2 } from 'styles/typography'
import { goToTop } from 'components/navbar'

// The intro section for tips

// Container for the whole section
const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${(props) => props.theme.primaryColor.cloud[10]};
    gap: ${(props) => props.theme.spacing.xxl.default};
    padding: ${(props) => props.theme.spacing.xl} ${(props) => props.theme.spacing.xxl.default};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        gap: ${(props) => props.theme.spacing.l};
        padding: ${(props) => props.theme.spacing.xxl.default} ${(props) => props.theme.spacing.l};
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        padding: ${(props) => props.theme.spacing.l} ${(props) => props.theme.spacing.s};
    }
`

// Wrapper for the heading
const HeadingWarpper = styled(HeadingsH2)`
    text-align: center;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        font-family: ${(props) => props.theme.typography.headingsH3.fontFamily};
        font-size: ${(props) => props.theme.typography.headingsH3.size};
        font-weight: ${(props) => props.theme.typography.headingsH3.weight};
        line-height: ${(props) => props.theme.typography.headingsH3.lineheight};
        letter-spacing: ${(props) => props.theme.typography.headingsH3.letterSpacing};
    }
`
// Container for all the tips
const TipsContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: ${(props) => props.theme.spacing.m};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        width: 100%;
        gap: ${(props) => props.theme.spacing.s};
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        width: 100%;
    }
`
// Wrapper for each tip
const TipWrapper = styled(Card)`
    width: 304px;
    height: 304px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
    padding: ${(props) => props.theme.spacing.m};
    gap: ${(props) => props.theme.spacing.s};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        width: 324px;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        width: 312px;
        height: 312px;
    }
    img {
        width: 96px;
    }
    div {
        width: 240px;
        text-align: center;
        font-family: ${(props) => props.theme.typography.subheading20.fontFamily};
        font-size: ${(props) => props.theme.typography.subheading20.size};

        line-height: ${(props) => props.theme.typography.subheading20.lineheight};
        letter-spacing: ${(props) => props.theme.typography.subheading20.letterSpacing};
        @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
            width: 260px;
        }
        @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
            width: 248px;
        }
    }
`

export function TipsIntro() {
    // Function to navigate to a certain page
    const navigate = useNavigate()

    // Function to change the path to '/calculator'
    const routeChange = () => {
        const path = `/calculator`
        navigate(path)
        goToTop()
    }

    // Function to handle a click on the 'Test another page' button
    const handleClick = () => {
        routeChange()
        window.location.reload()
    }

    return (
        <Container>
            <HeadingWarpper>What can you do to reduce your website’s carbon footprint?</HeadingWarpper>
            <TipsContainer>
                <TipWrapper>
                    <img src={greenHosting} alt='greenhosting' />
                    <div>Switch your digital assets to green hosting</div>
                    {/* <PlainLink to='/'>Take Action</PlainLink> */}
                    {/* "TODO: uncomment once the paper is published" */}
                </TipWrapper>
                <TipWrapper>
                    <img src={caches} alt='caches' />
                    <div>Use caches to make data requests more efficient</div>
                    {/* <PlainLink to='/'>Learn More</PlainLink> */}
                    {/* "TODO: uncomment once the paper is published" */}
                </TipWrapper>
                <TipWrapper>
                    <img src={dataCentre} alt='datacentre' />
                    <div>Rethink your data centre locations</div>
                    {/* <PlainLink to='/'>Learn More</PlainLink> */}
                    {/* "TODO: uncomment once the paper is published" */}
                </TipWrapper>
                <TipWrapper>
                    <img src={audit} alt='audit' />
                    <div>Get a full audit of your Scope 3 emissions</div>
                    {/* <PlainLink to='/contact'>Contact Us</PlainLink> */}
                    {/* "TODO: uncomment once the paper is published" */}
                </TipWrapper>
            </TipsContainer>
            <SmallBlackButton style={{ width: '247px', padding: 'unset' }} onClick={handleClick}>
                Test another web page
            </SmallBlackButton>
        </Container>
    )
}

export default TipsIntro
