import styled from '@emotion/styled'
import { AllCaps16, AllCaps20, HeadingsH1, Paragraph18, Subheading20 } from 'styles/typography'
import tree from '../assets/icons/tree-blue.svg'
import sprout from '../assets/icons/sprout.svg'
import checkmarkGreen from '../assets/icons/checkmark-green.svg'
import checkmarkBlue from '../assets/icons/checkmark-blue.svg'
import { BigLakeButton, BigPineButton } from 'components/atoms/button'
import { Link } from 'react-router-dom'

// Container for section
const SectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${(props) => props.theme.spacing.xxl.default};
    padding: ${(props) => props.theme.spacing.xxl.default};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        gap: ${(props) => props.theme.spacing.l};
        padding: ${(props) => props.theme.spacing.xxl.default} ${(props) => props.theme.spacing.l};
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        padding: ${(props) => props.theme.spacing.l} ${(props) => props.theme.spacing.s};
    }
`

// Container for header
const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${(props) => props.theme.spacing.m};
`

// Section pretext
const SectionPretext = styled(AllCaps16)`
    color: ${(props) => props.theme.primaryColor.pine[75]};
`

// Section title
const SectionTitle = styled(HeadingsH1)`
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        font-family: ${(props) => props.theme.typography.headingsH2.fontFamily};
        font-size: ${(props) => props.theme.typography.headingsH2.size};
        font-weight: ${(props) => props.theme.typography.headingsH2.weight};
        line-height: ${(props) => props.theme.typography.headingsH2.lineheight};
        letter-spacing: ${(props) => props.theme.typography.headingsH2.letterSpacing};
    }
`
// Container for subheading
const SubheaderContainer = styled.div`
    width: 100%;
    max-width: 1088px;
    border-top: ${(props) => props.theme.input.border} solid ${(props) => props.theme.primaryColor.black[100]};
    border-bottom: ${(props) => props.theme.input.border} solid ${(props) => props.theme.primaryColor.black[100]};
    padding: ${(props) => props.theme.spacing.m} 0px;
    text-align: center;
`

// Section subheader
const SectionSubheader = styled(Subheading20)`
    color: ${(props) => props.theme.primaryColor.pine[75]};
`

// Container for pricing cards
const PricingCardsContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1088px;
    gap: ${(props) => props.theme.spacing.xxl.default};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        flex-direction: column;
        gap: ${(props) => props.theme.spacing.m};
    }
`

// Container for pricing card
const PricingCardContainer = styled.div`
    flex: 1 0 0;
    display: flex;
    padding: ${(props) => props.theme.spacing.m};
    flex-direction: column;
    align-items: flex-start;
    gap: ${(props) => props.theme.spacing.m};
    border-radius: 16px;
    background: ${(props) => props.theme.primaryColor.white};
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.05);
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        gap: ${(props) => props.theme.spacing.s};
    }
`

// Wrapper for the pricing card heading
const PricingCardHeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: ${(props) => props.theme.spacing.xs};
`

// Free pricing card title
const FreePricingCardTitle = styled(AllCaps20)`
    border-radius: 4px;
    background: ${(props) => props.theme.primaryColor.pine[50]};
    padding: 0px ${(props) => props.theme.spacing.xs};
    color: ${(props) => props.theme.primaryColor.white};
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        font-family: ${(props) => props.theme.typography.allCaps18.fontFamily};
        font-size: ${(props) => props.theme.typography.allCaps18.size};
        font-weight: ${(props) => props.theme.typography.allCaps18.weight};
        line-height: ${(props) => props.theme.typography.allCaps18.lineheight};
        letter-spacing: ${(props) => props.theme.typography.allCaps18.letterSpacing};
    }
`

// Enterprise pricing card title
const EnterprisePricingCardTitle = styled(AllCaps20)`
    color: ${(props) => props.theme.primaryColor.lake[75]};
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        font-family: ${(props) => props.theme.typography.allCaps18.fontFamily};
        font-size: ${(props) => props.theme.typography.allCaps18.size};
        font-weight: ${(props) => props.theme.typography.allCaps18.weight};
        line-height: ${(props) => props.theme.typography.allCaps18.lineheight};
        letter-spacing: ${(props) => props.theme.typography.allCaps18.letterSpacing};
    }
`

// Pricing card info
const PricingCardInfo = styled(Paragraph18)`
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        font-family: ${(props) => props.theme.typography.paragraph18.fontFamily};
        font-size: ${(props) => props.theme.typography.paragraph18.size};
        font-weight: ${(props) => props.theme.typography.paragraph18.weight};
        line-height: ${(props) => props.theme.typography.paragraph18.lineheight};
        letter-spacing: ${(props) => props.theme.typography.paragraph18.letterSpacing};
    }
`

// Pricing card divider
const PricingCardDivider = styled.div`
    width: 100%;
    height: 1px;
    background: ${(props) => props.theme.primaryColor.black[25]};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        width: 100%;
    }
`

// Container for pricing card body
const PricingCardBodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    height: 100%;
    gap: ${(props) => props.theme.spacing.m};
`

// Container for pricing card features
const PricingCardFeaturesContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: ${(props) => props.theme.spacing.s};
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        gap: ${(props) => props.theme.spacing.xs};
    }
`

// Pricing card features title
const PricingCardFeaturesTitle = styled(Subheading20)`
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        font-family: ${(props) => props.theme.typography.subheading18.fontFamily};
        font-size: ${(props) => props.theme.typography.subheading18.size};
        font-weight: ${(props) => props.theme.typography.subheading18.weight};
        line-height: ${(props) => props.theme.typography.subheading18.lineheight};
        letter-spacing: ${(props) => props.theme.typography.subheading18.letterSpacing};
    }
`

// Container for pricing card feature
const PricingCardFeatureContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${(props) => props.theme.spacing.s};
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        gap: ${(props) => props.theme.spacing.xs};
    }
`

// Pricing card feature text
const PricingCardFeatureText = styled(Paragraph18)`
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        font-family: ${(props) => props.theme.typography.paragraph16.fontFamily};
        font-size: ${(props) => props.theme.typography.paragraph16.size};
        font-weight: ${(props) => props.theme.typography.paragraph16.weight};
        line-height: ${(props) => props.theme.typography.paragraph16.lineheight};
        letter-spacing: ${(props) => props.theme.typography.paragraph16.letterSpacing};
    }
`

// Wrapper for link
const LinkWrapper = styled(Link)`
    text-decoration: none;
`

// Custom BigLakeButton
const CustomBigLakeButton = styled(BigLakeButton)`
    width: 100%;
`

// PricingCardFeature component
function PricingCardFeature(props: any) {
    const { icon, text } = props

    return (
        <PricingCardFeatureContainer>
            <img src={icon} alt='checkmark' />
            <PricingCardFeatureText>{text}</PricingCardFeatureText>
        </PricingCardFeatureContainer>
    )
}

// PricingCard component
function PricingCard(props: any) {
    const { cardIcon, headerTitleElement, headerInfoText, featuresTitleText, featureIcon, featureTexts, bodyButton } =
        props

    return (
        <PricingCardContainer>
            <img src={cardIcon} alt='tree' />
            <PricingCardHeaderContainer>
                {headerTitleElement}
                <PricingCardInfo>{headerInfoText}</PricingCardInfo>
            </PricingCardHeaderContainer>
            <PricingCardDivider />
            <PricingCardBodyContainer>
                <PricingCardFeaturesContainer>
                    <PricingCardFeaturesTitle>{featuresTitleText}</PricingCardFeaturesTitle>
                    {featureTexts.map((featureText: string, index: number) => (
                        <PricingCardFeature key={index} icon={featureIcon} text={featureText} />
                    ))}
                </PricingCardFeaturesContainer>
                {bodyButton}
            </PricingCardBodyContainer>
        </PricingCardContainer>
    )
}

export default function Pricing() {
    return (
        <SectionContainer>
            <HeaderContainer>
                <SectionPretext>Pricing</SectionPretext>
                <SectionTitle>Digital Scope SDK</SectionTitle>
            </HeaderContainer>
            <SubheaderContainer>
                <SectionSubheader>Get clear, continual data on how much CO₂ your website produces</SectionSubheader>
            </SubheaderContainer>
            <PricingCardsContainer>
                <PricingCard
                    cardIcon={sprout}
                    headerTitleElement={<FreePricingCardTitle>Free</FreePricingCardTitle>}
                    headerInfoText='For small businesses and growing websites'
                    featuresTitleText='Data for up to 1 billion page views:'
                    featureIcon={checkmarkGreen}
                    featureTexts={['Daily and monthly CO₂ data', 'Access to dashboard', 'Digital Scope website badge']}
                    bodyButton={
                        <LinkWrapper to='/signup'>
                            <BigPineButton>Sign up</BigPineButton>
                        </LinkWrapper>
                    }
                />
                <PricingCard
                    cardIcon={tree}
                    headerTitleElement={<EnterprisePricingCardTitle>Enterprise</EnterprisePricingCardTitle>}
                    headerInfoText='For high-traffic websites'
                    featuresTitleText='Data for more than 1 billion page views:'
                    featureIcon={checkmarkBlue}
                    featureTexts={[
                        'Daily and monthly CO₂ data',
                        'Access to dashboard',
                        'Digital Scope website badge',
                        'Dedicated support team',
                    ]}
                    bodyButton={
                        <LinkWrapper to='/contact'>
                            <CustomBigLakeButton>Contact us</CustomBigLakeButton>
                        </LinkWrapper>
                    }
                />
            </PricingCardsContainer>
        </SectionContainer>
    )
}
