import styled from '@emotion/styled'
import { AllCaps16, HeadingsH2, Paragraph18 } from 'styles/typography'
import * as Calc from '../calculator/calculatorComponents'
import rightIcon from '../../assets/icons/Icon-left-half.svg'
import leftIcon from '../../assets/icons/Icon-right-half.svg'
import { useTheme } from '@emotion/react'

//wrapper for indexing
const PageWrapper = styled.div`
    position: relative;
`
//wrapper for flex box
const ContentWrapper = styled.div`
    display: flex;
    height: auto;
    justify-content: center;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        height: ${(props) => props.theme.home.height.tablet};
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        height: ${(props) => props.theme.home.height.mobile};
    }
`
//wrapper for content margin
const TextContainer = styled.div`
    padding: ${(props) => props.theme.spacing.xl} 0px;
    width: ${(props) => props.theme.home.width.desktopContainer};
    display: flex;
    flex-direction: column;
    position: absoulute;
    z-index: 5;
    align-items: center;
    justify-content: space-between;
    color: ${(props) => props.theme.primaryColor.white};
    gap: ${(props) => props.theme.spacing.gap};
    text-align: center;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        padding: unset;
        margin: ${(props) => props.theme.spacing.xxl.default} 0px;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        width: ${(props) => props.theme.home.width.mobileContainer};
    }
`

export const TakeAction = () => {
    const theme = useTheme()

    return (
        <PageWrapper>
            <Calc.PageWrapper style={{ backgroundColor: theme.primaryColor.lake[75], height: '100%' }}>
                <Calc.HomeWrapper style={{ height: '100%', alignItems: 'center' }}>
                    <Calc.LeftImageWrapper src={leftIcon} alt='logo' style={{ margin: '0px' }} />
                    <Calc.RightImageWrapper src={rightIcon} alt='logo' />
                </Calc.HomeWrapper>
            </Calc.PageWrapper>
            <ContentWrapper>
                <TextContainer>
                    <AllCaps16>Take Action</AllCaps16>
                    <HeadingsH2>
                        Fully integrated into your website for clear, continual data on your digital carbon footprint
                    </HeadingsH2>
                    <Paragraph18 style={{ color: 'white' }}>
                        Sign up to receive the SDK and start tracking your website’s emissions through a simple line of
                        code that you copy and paste into your website.
                    </Paragraph18>
                </TextContainer>
            </ContentWrapper>
        </PageWrapper>
    )
}
