import styled from '@emotion/styled'

//wrapper for the background size
export const BackgroundWrapper = styled.div`
    height: auto;
    display: flex;
    justify-content: center;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        height: ${(props) => props.theme.corporate.height.backgroundTablet};
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        height: ${(props) => props.theme.corporate.height.backgroundMobile};
    }
`
//wrapper to control the background flexboxes
export const ContentWrapper = styled.div`
    padding: ${(props) => props.theme.spacing.xl} ${(props) => props.theme.spacing.xxl.default};
    width: 100%;
    max-width: 1088px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${(props) => props.theme.spacing.xxl.default};
    align-items: center;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        padding: ${(props) => props.theme.spacing.xxl.default} ${(props) => props.theme.spacing.l};
        width: unset;
        gap: ${(props) => props.theme.spacing.l};
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        margin: ${(props) => props.theme.spacing.xxl.default} ${(props) => props.theme.spacing.s};
    }
`
