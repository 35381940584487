import styled from '@emotion/styled'
import { HeadingsH1 } from 'styles/typography'
import { Link } from 'react-router-dom'

// Privacy Policy Page

const ContainerWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
`

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: ${(props) => props.theme.primaryColor.cloud[10]};
    padding: ${(props) => props.theme.spacing.xl} 176px;
    gap: ${(props) => props.theme.spacing.s};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        padding: ${(props) => props.theme.spacing.xxl.default} ${(props) => props.theme.spacing.l};
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        padding-left: ${(props) => props.theme.spacing.s};
        padding-right: ${(props) => props.theme.spacing.s};
    }
`
// Wrapper for the heading
const HeadingWarpper = styled(HeadingsH1)`
    width: 100%;
    max-width: 1440px;
    font-size: ${(props) => props.theme.typography.headingsH2.size};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        font-family: ${(props) => props.theme.typography.headingsH2.fontFamily};
        font-size: ${(props) => props.theme.typography.headingsH2.size};
        font-weight: ${(props) => props.theme.typography.headingsH2.weight};
        line-height: ${(props) => props.theme.typography.headingsH2.lineheight};
        letter-spacing: ${(props) => props.theme.typography.headingsH2.letterSpacing};
    }
`

const ParagraphWrapper = styled.div`
    width: 100%;
    max-width: 1440px;
    color: ${(props) => props.theme.primaryColor.black[75]};
    font-family: ${(props) => props.theme.typography.paragraph18.fontFamily};
    font-size: ${(props) => props.theme.typography.paragraph18.size};
    font-weight: ${(props) => props.theme.typography.paragraph18.weight};
    line-height: ${(props) => props.theme.typography.paragraph18.lineheight};
    letter-spacing: ${(props) => props.theme.typography.paragraph18.letterSpacing};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        width: unset;
    }
`

// Link with no styling that disappears when the page is mobile screen size
export const PlainLink = styled(Link)`
    text-decoration: none;
    border-bottom: 2px solid #5177b8;
    font-family: ${(props) => props.theme.typography.paragraph18.fontFamily};
    font-size: ${(props) => props.theme.typography.paragraph18.size};
    font-weight: ${(props) => props.theme.typography.paragraph18.weight};
    line-height: ${(props) => props.theme.typography.paragraph18.lineheight};
    letter-spacing: ${(props) => props.theme.typography.paragraph18.letterSpacing};
    color: ${(props) => props.theme.primaryColor.black[75]};
    &:hover {
        color: #5177b8;
        text-decoration: none;
        border-bottom: 2px solid #5177b8;
    }
`
//constant to scroll page to top
export const goToTop = () => {
    window.scrollTo({
        top: 0,
    })
}

const PrivacyPolicy = () => (
    <ContainerWrapper>
        <Container>
            <HeadingWarpper
                style={{ fontSize: '56px', display: 'flex', justifyContent: 'center', textAlign: 'center' }}
            >
                Privacy Policy
            </HeadingWarpper>
            <ParagraphWrapper>
                This Privacy Policy was last revised and shall become effective as of OCTOBER 1, 2022. <br /> <br />
                Thank you for visiting digitalscope.com (the "Site"). The Site is an Internet property of Redbrick
                Technologies Inc. and its affiliated companies ("Redbrick", “our”, "we" or "us"). Redbrick is committed
                to protecting your privacy. We have prepared this privacy policy ("Privacy Policy") to describe to you
                and to outline our practices and policies associated with any information that we may collect from you,
                or that you may share with us when you access and use the Site, and through your use of the Redbrick
                software application(s) and/or tools (the “Software”), the services offered by Redbrick (the
                “Services”), or use any related products and/or services (the “Related Materials”). <br /> <br /> BY
                ACCESSING THE SITE OR USING THE SOFTWARE, THE SERVICES, AND/OR ANY RELATED MATERIALS, YOU AGREE TO THE
                TERMS AND CONDITIONS OF THIS PRIVACY POLICY IN ITS ENTIRETY.{' '}
                <ul>
                    <li>
                        We collect information about you and how you use the Software, the Services, and/or Related
                        Materials.
                    </li>
                    <li>
                        We collect Anonymous Data (as defined below) to make the Site, the Software, the Services, and
                        Related Materials more useful to you.
                    </li>
                    <li>
                        If we do collect Personal Data (as defined below), we will only use this information for lawful
                        purposes and will store and retain this information for as long as required by applicable law.
                    </li>
                    <li>
                        If you subscribe to our newsletters, we may send you emails about the Software, the Services,
                        and Related Materials, and other stuff that may be of interest to you. You can unsubscribe from
                        our marketing emails at any time.
                    </li>
                    <li>
                        You can contact us any time to update or remove any Personal Data that we have collected and
                        stored about you.
                    </li>
                </ul>
            </ParagraphWrapper>
            <HeadingWarpper>Lawful Basis for Processing Data</HeadingWarpper>
            <ParagraphWrapper>
                In certain jurisdictions, the processing of your Personal Data (as defined below) is lawful only if it
                is permitted under data protection legislation. In this case, we have a lawful basis for each of our
                processing activities (unless an exemption applies) as outlined below:
                <br />
                <ul>
                    <li>
                        Consent. By using the Site, the Software, the Services, the Related Materials, or otherwise
                        submitting your Personal Data to us, you consent to our collection, use and sharing of your
                        information as described in this Privacy Policy.
                    </li>
                    <li>
                        Legal Obligation. We will process your Personal Data where the processing is necessary to comply
                        with applicable law
                    </li>
                    <li>
                        Legitimate Interest. We will process your Personal Data based on Legitimate Interest,
                        particularly where it is used for fraud prevention and business and support purposes.
                    </li>
                    <li>
                        Contract. We may process your Personal Data where such processing is required in order to
                        fulfill an agreement or contract with you.
                    </li>
                </ul>
            </ParagraphWrapper>
            <HeadingWarpper>How We Collect and Use Personal and Anonymous Data</HeadingWarpper>
            <ParagraphWrapper>
                When you access the Site or use the Software, Services, and/or Related Materials in any way, we may
                collect Personal Data and Anonymous Data from you. We may also collect Personal Data and Anonymous Data
                when you send us information or communications. <br /> <br />
                “Personal Data” means data that allows someone to identify or contact you including, for example, and
                without limitation:
                <ul>
                    <li>your name;</li>
                    <li>your Internet Protocol (IP) address;</li>
                    <li>your electronic mail (email) address;</li>
                    <li>your phone number; and</li>
                    <li>your geographic location or geolocation</li>
                </ul>
                Personal Data may be used for the purposes of recording the transaction when you access the Site, when
                you use any of the Software, the Services and/or Related Materials. Redbrick does not knowingly collect
                any sensitive personal data or any data defined under special categories of the applicable law(s).{' '}
                <br /> <br />
                We may also use Personal Data for the following purposes:
                <ul>
                    <li>to fulfill your requests for the Software, the Services, and any Related Materials;</li>
                    <li>for use in internal business analytics;</li>
                    <li>to prevent fraud;</li>
                    <li>
                        to send you information about the Software, the Services, and any Related Materials, including
                        without limitation, technical notices, updates, security alerts, and support and administrative
                        messages;
                    </li>
                    <li>
                        to engage with third-party service providers so that you can access and use the Software, the
                        Services, and any Related Materials;
                    </li>
                    <li>to respond to your communications with Redbrick;</li>
                    <li>
                        to communicate with you about products and/or services offered by Redbrick, its affiliated
                        companies, and its selected partners;
                    </li>
                    <li>
                        to contact you to resolve problems that you may encounter with the Site, the Software, the
                        Services, and any Related Materials; and
                    </li>
                    <li>
                        to periodically conduct product and market research regarding your use of the Software, the
                        Services, and any Related Materials.
                    </li>
                </ul>
                If and when we use a third-party technical support provider, third-party technology provider, or
                third-party hosting provider, we ensure that the same privacy criteria, as defined herein with respect
                to Personal Data, are in place with such third parties. We do not (knowingly) collect personal
                information from persons under the age of (18) eighteen. <br /> <br />
                “Anonymous Data” refers to data about you that is not associated with or linked to your Personal Data.
                Anonymous Data does not permit the identification of individual persons. If and when we use a
                third-party technical support provider or third-party hosting provider, we ensure that the same privacy
                criteria as defined herein with respect to Anonymous Data are in place with such providers. We do not
                (knowingly) collect personal information from persons under the age of (18) eighteen. <br /> <br />
                To make the Site, the Software, the Services, and any Related Materials more useful to you, our servers
                (which may be hosted by a third-party service provider) may collect Anonymous Data from you such as web
                browser type, operating system type and version, domain name, Software version, Software usage and
                statistics, and/or a date/time stamp of your visit. We may use cookies and navigational data like
                Uniform Resource Locators (URL) and referring URLs to gather information regarding the date and time of
                your visit or the domain from which you accessed the Site, the Software, and/or the Services. We may use
                Anonymous Data for market research purposes and to improve our products and services, the Site, the
                Software, the Services, and any Related Materials. <br /> <br />
                We may share Anonymous Data with our affiliated companies, selected third parties, and business
                partners, however, we will not share any Anonymous Data if such information is, contains, or will be
                linked to your Personal Data, unless we have given you notice, as described in this Privacy Policy, and
                you have given us authorization to do so. Redbrick will use Personal Data, including IP addresses, to
                identify users of the Site, the Software, the Services, and Related Materials if and when we feel it is
                necessary to enforce compliance with the Site’s policies, or to protect our products and services, the
                Software, the Services, the Related Materials, the Site, or other users of the Site, Software, Services,
                and/or Related Materials. <br /> <br />
                We reserve the right to send you notices regarding any updates to the Software, the Services, the
                Related Materials, and our Terms of Services and Privacy Policy.
            </ParagraphWrapper>
            <HeadingWarpper>Sharing and Retention of Personal Data</HeadingWarpper>
            <ParagraphWrapper>
                We will never sell, trade, or rent any Personal Data, or any Anonymous Data that may contain Personal
                Data, to any third party. <br /> <br />
                In certain special cases, we may use, retain, or disclose your Personal Data when we have reason to
                believe that using, retaining, or disclosing this information is necessary to identify, contact, or
                bring legal action against someone who may be causing injury to you, or otherwise injuring or
                interfering with Redbrick's rights, property or operations, other Redbrick users, or anyone else who
                could be harmed by such activities. We may also use, retain, or disclose your Personal Data when we
                believe the law requires it, or in response to any demand by law enforcement authorities in connection
                with a criminal investigation, or civil or administrative authorities in connection with a pending civil
                case or administrative investigation. <br /> <br />
                If we determine, in our sole discretion, that you have engaged in conduct which might be considered,
                unlawful, fraudulent, or which might harm or damage the reputation or standing of Redbrick with either
                the general public or with a business partner or potential business partner of Redbrick, we reserve the
                right to release your Personal Data to such persons or third parties as we consider necessary in order
                to prevent you from causing injury to, or otherwise injuring or interfering, now or in the future, with
                Redbrick's rights, property or operations or otherwise the rights, property or operations of anyone else
                who could be harmed by such conduct. <br /> <br />
                We offer you choices regarding the collection, use, and sharing of your Personal Data. We may
                periodically send you newsletters and emails that directly promote the use of the Site, the Services,
                the Software, and any Related Materials. When you receive newsletters or promotional communications from
                us, you may indicate a preference to stop receiving further communications from us and you will have the
                opportunity to “opt-out” by following the unsubscribe instructions provided in the e-mail you receive,
                or by contacting us directly (please see contact information below). Should you decide to opt-out of
                receiving future mailings from us, you may continue to receive future mailings from third parties that
                you have requested to receive information from. If you do not wish to receive future communications from
                third-party providers, you should notify them directly of your choice regarding their use of your
                Personal Data. To opt-out of having future third-party marketing communications sent to you, you will be
                required to unsubscribe with the applicable third-party providers. Despite your request to no longer
                receive future newsletters or promotional and marketing communications from us, we reserve the right to
                continue to send you notices of any updates to the Software, the Services, the Related Materials, the
                Terms of Service, and this Privacy Policy.
            </ParagraphWrapper>
            <HeadingWarpper>Your Rights With Respect to Your Personal Data</HeadingWarpper>
            <ParagraphWrapper>
                This section applies if you are located in the European Union (EU) or European Economic Area (EEA). In
                accordance with applicable privacy law, you have the following rights in respect of your personal
                information that we hold:
                <ul>
                    <li>
                        Right of access and portability. The right to obtain access to and to receive your Personal
                        Data.
                    </li>
                    <li>
                        Right to rectification. The right to obtain rectification of your Personal Data without undue
                        delay where that personal information is inaccurate or incomplete.
                    </li>
                    <li>
                        Right to erasure. The right to obtain the erasure of your Personal Data without undue delay in
                        certain circumstances, such as where the Personal Data is no longer necessary in relation to the
                        purposes for which it was collected or processed.
                    </li>
                    <li>
                        Right to restriction. The right to obtain the restriction of the processing undertaken by us on
                        your Personal Data in certain circumstances, such as where the accuracy of the Personal Data is
                        contested by you, for a period enabling us to verify the accuracy of that Personal Data.
                    </li>
                    <li>
                        Right to object. The right to object, on grounds relating to your particular situation, to the
                        processing of your Personal Data, and to object to processing of your Personal Data for direct
                        marketing purposes, to the extent it is related to such direct marketing.
                    </li>
                </ul>
                If you wish to exercise one of these rights, please contact us using the contact details at the end of
                this Privacy Policy. You also have the right to lodge a complaint to your local data protection
                authority. <br /> <br />
                This section applies if you are a resident in the State of California. In accordance with the California
                Consumer Privacy Act (CCPA), you have certain rights with respect to the processing of your personal
                information. Under CCPA, you have a right to request that we provide you with the following:
                <ul>
                    <li>
                        Categories of information. The categories and specific pieces of personal information we have
                        collected about you.
                    </li>
                    <li>
                        Categories of sources. The categories of sources from which we collect personal information.
                    </li>
                    <li>Purpose. The purposes for collecting, using, or selling personal information.</li>
                    <li>
                        Categories of third parties. The categories of third parties with which we share personal
                        information.
                    </li>
                    <li>
                        Business purpose. For companies that disclose personal information for business purposes, the
                        categories of personal information disclosed about you for such business purposes.
                    </li>
                    <li>
                        Selling of information. For companies that “sell” personal information, the categories of
                        personal information that are being sold and the categories of third parties to which the
                        personal information is being sold. Note that Redbrick is sharing personal information for
                        business purposes and does not “sell” personal information as defined by the CCPA.
                    </li>
                </ul>
                You also have a right to request that we update or delete personal information under certain
                circumstances, subject to certain exceptions and requirements. If you wish to exercise one of these
                rights, please contact us using the contact details at the end of this Privacy Policy. <br /> <br />
                We reserve the right to limit or deny your request to view, update, or remove your Personal Data if you
                have failed to provide us with sufficient information to verify your identity.
            </ParagraphWrapper>
            <HeadingWarpper>Cookies and Similar Technologies</HeadingWarpper>
            <ParagraphWrapper>
                A cookie is a small piece of text that is sent to your web browser by the Site. The cookie helps the
                Site remember information about your visit, which makes your next visit to the Site easier and the Site
                more useful to you. We also use cookies to count how many visitors we receive to a page on the Site. No
                personally identifiable information is collected or stored by Redbrick during this process. We may use
                your IP address to identify your general location for traffic monitoring purposes. If you do not wish to
                receive cookies, you may set your browser to reject cookies or to alert you when a cookie is placed on
                your computer. You are not required to accept cookies, however, if your browser rejects our cookies you
                may not be able to use all of the Site functionality. <br /> <br />
                If you opt-out of having your information collected through cookies and other web technologies, your
                existing display advertising cookie(s) will be deleted and a new cookie will attempt to be placed that
                instructs third-party service providers not to track your future activities when that cookie is detected
                (a “do-not-track” cookie). If your browsers are configured to reject cookies when you visit our opt-out
                page, a “do-not-track” cookie cannot be set on your computer. Also, if you subsequently erase
                “do-not-track” cookies, use a different computer or change web browsers, you will need to opt-out again.
                We currently do not respond to browser "do-not-track" signals.
            </ParagraphWrapper>
            <HeadingWarpper>Third-party Relationships</HeadingWarpper>
            <ParagraphWrapper>
                <b style={{ fontWeight: '600' }}>Third-party Technology/Software/Services/Solutions Providers</b> - The
                Site, the Software, the Services, and Related Materials may contain certain software technologies and
                services licensed from third-party providers. Redbrick may engage with certain third-party services and
                solutions providers such as data hosting service providers, traffic monitoring service providers, and
                advertising service providers. These third-party providers have separate privacy and data collection
                practices and policies, and Redbrick has no responsibility or liability relating to them. We do not
                endorse, nor are we responsible for the accuracy of the terms and conditions of any third-party privacy
                policies. These third-party entities are independent third parties and are not affiliated with Redbrick.{' '}
                <br /> <br />
                edbrick may be using or sharing your Personal Data for Legitimate Interest purposes with third-party
                technology and service providers. For more information about these third-party service providers, please{' '}
                <PlainLink to='/privacy/relationships' onClick={goToTop}>
                    CLICK HERE.
                </PlainLink>
            </ParagraphWrapper>
            <HeadingWarpper>Security of Data</HeadingWarpper>
            <ParagraphWrapper>
                Redbrick is committed to protecting the security of any Personal Data that is collected by us. We use a
                variety of industry-standard security technologies and procedures to help protect any Personal Data from
                unauthorized access, use, or disclosure. Despite these measures, no method of transmission over the
                Internet, or method of electronic storage, is 100% secure. Redbrick cannot fully eliminate security
                risks associated with the collection and storage of any Personal Data, including without limitation, any
                mistakes and security breaches that may happen. While we strive to use commercially acceptable means to
                protect your Personal Data, we cannot guarantee its absolute security. In the unlikely event of a data
                breach where your Personal Data has been compromised, Redbrick will promptly notify you, the applicable
                authorities and supervisory authorities or data protection authorities. <br /> <br />
                If you are located in the EU/EEA and Redbrick is required to transfer your Personal Data in accordance
                with this Privacy Policy, we exert commercially reasonable efforts to ensure that any transfer of your
                Personal Data outside of the EU/EEA is protected under industry standard contractual clauses, and any
                subsequent data protection safeguards. Where EU Data Protection Law applies, Redbrick shall not process
                its collected Personal Data (nor permit its collected Personal Data to be processed) in a territory
                outside of the EU/EEA unless it has taken such measures as are necessary to ensure the transfer is in
                compliance with EU Data Protection Law. Such measures may include (without limitation) transferring its
                collected Personal Data to a recipient in a country that the European Commission has decided provides
                adequate protection for Personal Data, to a recipient that has achieved binding corporate rules
                authorization in accordance with EU Data Protection Law, or to a recipient that has executed standard
                contractual clauses adopted or approved by the European Commission.
            </ParagraphWrapper>
            <HeadingWarpper>Data Retention Period</HeadingWarpper>
            <ParagraphWrapper>
                Any Personal Data that is collected and stored by Redbrick will be retained only for as long as required
                in order to provide certain products and/or services to you, or as required by applicable law. Any
                Personal Data that is no longer required by Redbrick will be destroyed.
            </ParagraphWrapper>
            <HeadingWarpper>Changes to Privacy Policy</HeadingWarpper>
            <ParagraphWrapper>
                We reserve the right, in our sole discretion, to change, modify, update and change this Privacy Policy
                at any time and without prior notice to you. Notice regarding any material changes to this Privacy
                Policy and how Redbrick will collect and use Personal Data will be placed in a prominent location on the
                Site or within the Software, the Services, and/or Related Materials and will take effect immediately
                upon their posting on the Site or within the Software, the Services, and/or Related Materials.
                Furthermore, Redbrick reserves the right to update, add, and/or remove third-party service providers
                with whom Redbrick may be using or sharing Personal Data for legitimate and lawful purposes without
                prior notice to you. Please check the Site and this Privacy Policy periodically for any changes. Your
                continued use of the Site, the Software, the Services, and/or Related Materials following the posting of
                changes to this Privacy Policy, or any changes to the third-party service providers with whom Redbrick
                may be using and sharing Personal Data for legitimate and lawful purposes, will constitute your
                acknowledgement and acceptance of any and all changes.
            </ParagraphWrapper>
            <HeadingWarpper>Contacting Us</HeadingWarpper>
            <ParagraphWrapper>
                If you have any questions regarding this Privacy Policy, would like to review, update or remove your
                Personal Data, or would like more information on our data collection, usage, and privacy practices,
                please contact us: <br /> <br />
                Redbrick Technologies Inc. <br />
                520 – 1515 Douglas St. <br />
                Victoria, BC V8W 2G4 <br />
                (250) 590-1800 <br />
                privacy@rdbrck.com
            </ParagraphWrapper>
        </Container>
    </ContainerWrapper>
)

export default PrivacyPolicy
