import SDKIntro from 'components/info/sdkIntro'
import TipsIntro from 'components/info/tipsIntro'

// The Info sections on Results page
const Info = () => (
    <div>
        <SDKIntro />
        <TipsIntro />
    </div>
)

export default Info
