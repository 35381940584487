import styled from '@emotion/styled'
import { BigBlackButton } from 'components/atoms/button'
import { HeadingsH1 } from 'styles/typography'

//wrapper to set the background color and size
export const PageWrapper = styled.div`
    background-color: ${(props) => props.theme.primaryColor.lake[75]};
    position: absolute;
    width: 100%;
    z-index: 1;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        height: 584px;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        height: 704px;
    }
`
//wrapper for flex boxes
export const HomeWrapper = styled.div`
    height: 752px;
    display: flex;
    position: relative;
    justify-content: space-between;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        height: 584px;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        height: ${(props) => props.theme.home.height.mobile};
    }
`
//wrapper for right-hand image margin
export const RightImageWrapper = styled.img`
    width: ${(props) => props.theme.home.width.image};
    height: ${(props) => props.theme.home.height.image};
    display: flex;
    position: absolute;
    right: 0;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        margin-top: 0px;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        margin-top: 0px;
    }
`
//wrapper for left-hand image margin
export const LeftImageWrapper = styled.img`
    width: ${(props) => props.theme.home.width.image};
    height: ${(props) => props.theme.home.height.image};
    margin-top: 270px;
    z-index: 3;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        margin-top: 120px;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        margin-top: 232px;
    }
`
//wrapper for indexing
export const MainPageWrapper = styled.div`
    position: relative;
`
//wrapper for flex box
export const ContentWrapper = styled.div`
    display: flex;
    justify-content: center;
    height: 752px;
    color: ${(props) => props.theme.primaryColor.white};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        height: 100%;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        height: 100%;
    }
`
//wrapper for content margin
export const ContentContainer = styled.div`
    margin: ${(props) => props.theme.spacing.xl} 0px;
    width: ${(props) => props.theme.home.width.desktopContainer};
    display: flex;
    flex-direction: column;
    z-index: 5;
    justify-content: space-between;
    gap: ${(props) => props.theme.spacing.xxl.default};
    text-align: center;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        margin: ${(props) => props.theme.spacing.xxl.default} ${(props) => props.theme.spacing.xxl.default};
        gap: ${(props) => props.theme.spacing.l};
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        margin: ${(props) => props.theme.spacing.xxl.default} ${(props) => props.theme.spacing.s};
    }
`
//wrapper for button sizing
export const CalculatorBlackButton = styled(BigBlackButton)`
    padding: unset;
    width: 194px;
    height: 64px;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        width: 137px;
        height: 40px;
        font-size: ${(props) => props.theme.typography.allCaps14.size};
        font-weight: ${(props) => props.theme.typography.allCaps14.weight};
        line-height: ${(props) => props.theme.typography.allCaps14.lineheight};
        letter-spacing: ${(props) => props.theme.typography.allCaps14.letterSpacing};
    }
`
//wrapper for heading style
export const HomeHeadings = styled(HeadingsH1)`
    color: ${(props) => props.theme.primaryColor.white};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        font-family: ${(props) => props.theme.typography.headingsH2.fontFamily};
        font-size: ${(props) => props.theme.typography.headingsH2.size};
        font-weight: ${(props) => props.theme.typography.headingsH2.weight};
        line-height: ${(props) => props.theme.typography.headingsH2.lineheight};
        letter-spacing: ${(props) => props.theme.typography.headingsH2.letterSpacing};
    }
`
//wrapper for header text
export const TextContainer = styled.div`
    gap: ${(props) => props.theme.spacing.m};
    display: flex;
    flex-direction: column;
`
//wrapper for input form
export const FormContainer = styled.form`
    display: flex;
    flex-direction: column;
    text-align: left;
`
//adjust button to center
export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: ${(props) => props.theme.spacing.normal};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        margin-top: 0px;
    }
`
//container for views form and icons
export const ViewsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: ${(props) => props.theme.spacing.normal};
    gap: ${(props) => props.theme.spacing.m};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        height: unset;
        margin-top: 0px;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        flex-direction: column;
        align-items: unset;
        gap: 0px;
    }
`
//container for icon placement
export const GreenHostingContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 28px;
    padding-bottom: 20px;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        padding-bottom: 35px;
        gap: 20px;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        flex-direction: row;
        height: ${(props) => props.theme.spacing.l};
        justify-content: center;
        align-items: center;
        padding-bottom: ${(props) => props.theme.spacing.m};
    }
`
//container for info icon and t
export const OptionalContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${(props) => props.theme.spacing.xxs};
`
//change input width
export const ViewsInput = styled.div`
    display: unset;
    width: 394px;
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        width: unset;
    }
`
