import styled from '@emotion/styled'
import { AllCaps16, Paragraph16, Subheading24 } from 'styles/typography'
import { Card } from 'components/atoms/card'
import Loading from 'assets/animations/loading.json'
import MobileLoading from 'assets/animations/mobileLoading.json'
import { useLottie, useLottieInteractivity } from 'lottie-react'
import { useEffect, useState } from 'react'
import { funFactData, funFactLoadingTitle } from 'data/loadingData'

// Container for the whole section
const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: auto;
    align-items: center;
    background-color: ${(props) => props.theme.primaryColor.cloud[10]};
    gap: ${(props) => props.theme.spacing.xxl.default};
    padding: ${(props) => props.theme.spacing.xl} ${(props) => props.theme.spacing.xxl.default};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        gap: ${(props) => props.theme.spacing.l};
        padding: ${(props) => props.theme.spacing.xxl.default} ${(props) => props.theme.spacing.l};
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        padding: ${(props) => props.theme.spacing.l} ${(props) => props.theme.spacing.s};
    }
`

// Wrapper for each tip
const ContentWrapper = styled(Card)`
    width: 100%;
    max-width: 864px;
    height: auto;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
    padding: ${(props) => props.theme.spacing.m};
    padding-bottom: 64px;
    gap: 40px;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        max-width: 672px;
        padding-bottom: 32px;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        width: 100%;
    }
`
// Wrapper for the heading part of the animation
const HeadingWrapper = styled(AllCaps16)`
    max-width: 640px;
    color: ${(props) => props.theme.primaryColor.lake[75]};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        font-size: ${(props) => props.theme.typography.allCaps14.size};
    }
`

// Wrapper for the fun fact part of the animation
const FactWrapper = styled(Subheading24)`
    max-width: 640px;
    color: ${(props) => props.theme.primaryColor.black[100]};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        font-size: ${(props) => props.theme.typography.subheading20.size};
        line-height: ${(props) => props.theme.typography.subheading20.lineheight};
    }
`

// Wrapper for the fun fact part of the animation
const TextWrapper = styled(Paragraph16)`
    color: ${(props) => props.theme.primaryColor.lake[75]};
    font-family: ${(props) => props.theme.typography.allCaps16.fontFamily};
    a {
        text-decoration: none;
        color: ${(props) => props.theme.primaryColor.lake[75]};
        font-family: ${(props) => props.theme.typography.allCaps16.fontFamily};
        font-size: ${(props) => props.theme.typography.paragraph16.size};
        line-height: ${(props) => props.theme.typography.paragraph16.lineheight};
        font-weight: ${(props) => props.theme.typography.paragraph16.weight};
        &:hover {
            color: #5177b8;
            text-decoration: none;
            border-bottom: 2px solid #5177b8;
        }
    }
`

// Wrapper for the Desktop image
const DesktopImageWrapper = styled.div`
    img {
        width: 690px;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        display: none;
    }
`

// Wrapper for the Mobile image
const MobileImageWrapper = styled.div`
    img {
        width: 246px;
    }
    @media (min-width: 581px) {
        display: none;
    }
`

// Configure the properties of the loading animation so it will only start when the user calculates carbon emisssion
const DesktopAnimationObject = {
    animationData: Loading,
}

const DesktopLoadingAnimation = () => {
    const lottieObj = useLottie(DesktopAnimationObject)
    const Animation = useLottieInteractivity({
        lottieObj,
        mode: 'scroll',
        actions: [
            {
                visibility: [0, 1],
                type: 'play',
                frames: [0],
            },
        ],
    })
    return Animation
}

const MobileLoadingAnimation = () => {
    const lottieObj = useLottie(MobileAnimationObject)
    const Animation = useLottieInteractivity({
        lottieObj,
        mode: 'scroll',
        actions: [
            {
                visibility: [0, 1],
                type: 'play',
                frames: [0],
            },
        ],
    })
    return Animation
}

const MobileAnimationObject = {
    animationData: MobileLoading,
}

// generating the random index for showing the different funfact at loading
const generateRandomIndex = (data: any) => Math.floor(Math.random() * data.length)

export default function LoadingAnimation(props: any) {
    const [index, setIndex] = useState(0)
    const [indexTitle, setIndexTitle] = useState(0)
    const [counter, setCounter] = useState(0)

    useEffect(() => {
        let x = counter
        const interval = setInterval(() => {
            x += 1
            if (x == 7) {
                setIndexTitle(indexTitle + 1)
            }
            if (x == 10) {
                setIndexTitle(indexTitle + 1)
            }
            if (x > 10) {
                clearInterval(interval)
            }
            setCounter(x)
        }, 1000)

        return () => clearInterval(interval)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [counter])

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex(generateRandomIndex(funFactData))
        }, 6000)

        return () => clearInterval(interval)
    }, [index])

    return (
        <Container>
            <ContentWrapper>
                <HeadingWrapper>{funFactLoadingTitle[indexTitle].name}</HeadingWrapper>
                <DesktopImageWrapper>
                    <DesktopLoadingAnimation />
                </DesktopImageWrapper>
                <MobileImageWrapper>
                    <MobileLoadingAnimation />
                </MobileImageWrapper>
                <FactWrapper>{funFactData[index].funFact}</FactWrapper>
                <TextWrapper>
                    Source:{' '}
                    <a href={funFactData[index].sourceLink} target='_blank' rel='noreferrer'>
                        {funFactData[index].sourceText}
                    </a>
                </TextWrapper>
            </ContentWrapper>
        </Container>
    )
}
