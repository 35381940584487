import styled from '@emotion/styled'
import { yupResolver } from '@hookform/resolvers/yup'
import { BigLakeButton, SmallBlackButton } from 'components/atoms/button'
import { DefaultInputBox, ErrorText, InputWrapper } from 'components/atoms/formInput'
import { DefaultLabel, RequiredLabel } from 'components/atoms/label'
import { useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { AllCaps16, Paragraph14, Paragraph16, Paragraph18 } from 'styles/typography'
import * as Yup from 'yup'
import rightIcon from '../../assets/icons/Icon-left-half-gray.svg'
import leftIcon from '../../assets/icons/Icon-right-half-gray.svg'
import * as Calc from '../calculator/calculatorComponents'
import config from '../../config.json'

// Container for sign up form
const SignUpContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: ${(props) => props.theme.spacing.xl} 176px;
    max-width: 1088px;
    gap: 64px;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        flex-direction: column;
        align-items: center;
        padding: ${(props) => props.theme.spacing.xxl.default} 120px;
        gap: 48px;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        padding-left: ${(props) => props.theme.spacing.s};
        padding-right: ${(props) => props.theme.spacing.s};
    }
`

// Container for form text
const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    text-align: left;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        align-items: center;
        width: 100%;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        width: 100%;
    }
`

// Styling for the 'Sign Up' title
const SignUpTitle = styled(AllCaps16)`
    color: ${(props) => props.theme.primaryColor.lake[75]};
    margin-bottom: ${(props) => props.theme.spacing.m};
`

// Styling for the SignUpParagraph
const SignUpParagraph = styled(Paragraph18)`
    color: ${(props) => props.theme.primaryColor.black[75]};
    margin-top: ${(props) => props.theme.spacing.m};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        text-align: center;
    }
`

// Custom heading based on HomeHeadings
const CustomHeading = styled(Calc.HomeHeadings)`
    color: ${(props) => props.theme.primaryColor.black[75]};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        text-align: center;
    }
`

// Wrapper for form
const FormWrapper = styled.form`
    display: flex
    flex-direction: column;
    width: 50%;
    justify-content: center;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        align-items: center;
        width: 100%;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        width: 100%;
    }
`

// Container for file upload
const FileUploadContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: ${(props) => props.theme.spacing.xxs};
`

// Container for the file upload button
const FileUploadButtonContainer = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
    gap: ${(props) => props.theme.spacing.xxs};
`

// Wrapper for input field instruction text
const InputInstructionWrapper = styled(Paragraph14)`
    color: ${(props) => props.theme.primaryColor.black[75]};
`

// Container for the submit button
const SubmitButtonContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        align-items: center;
    }
`

// Custom big lake button for the sign up form
const CustomBigLakeButton = styled(BigLakeButton)`
    width: 164px;
`

const SignUpForm = (props: any) => {
    const { setIsSuccessful } = props
    const [logoFile, setLogoFile] = useState<File | null>(null)
    const [fileUploadError, setFileUploadError] = useState(false)
    const inputRef = useRef<HTMLInputElement>(null)

    // Input validation object to check user inputs against
    const InputValidation = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        email: Yup.string().email('Please input a valid email').required('Email is required'),
        company: Yup.string().required('Company is required'),
        website: Yup.string().required('Website is required'),
    })

    // An object with methods to register InputValidation, handle submissions, and provide error information
    const {
        register, // method to register InputValidation
        handleSubmit, // method to handle submission
        formState: { errors, isSubmitted }, // contains error information
    } = useForm({
        mode: 'onSubmit',
        resolver: yupResolver(InputValidation),
    })

    // Handler for when file upload button is clicked
    const handleClick = () => {
        inputRef.current?.click()
    }

    // Handler for form submission (triggered when form is submitted and has no errors)
    const submitData = (data: unknown) => {
        const formData = new FormData()
        formData.append('file', logoFile || '')
        formData.append('data', JSON.stringify(data))

        try {
            fetch(`${config.apiUrl}/signup`, {
                method: 'POST',
                mode: 'no-cors',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: formData,
            })
        } catch (error) {
            console.error(error)
        }

        setIsSuccessful(true)
    }

    // Handler for when file is uploaded
    const handleFileUpload = (event: any) => {
        setLogoFile(event.target.files[0])

        if (event.target.files) {
            const img = new Image()
            img.src = URL.createObjectURL(event.target.files[0])
            img.onload = () => {
                if (img.width >= 128 && img.height >= 128) {
                    setFileUploadError(false)
                } else {
                    setLogoFile(null)
                    setFileUploadError(true)
                }
            }
        }
    }

    return (
        <Calc.MainPageWrapper>
            <Calc.PageWrapper style={{ backgroundColor: '#f5f6f4', height: '100%' }}>
                <Calc.HomeWrapper style={{ height: '100%' }}>
                    <Calc.LeftImageWrapper src={leftIcon} alt='logo' />
                    <Calc.RightImageWrapper src={rightIcon} alt='logo' />
                </Calc.HomeWrapper>
            </Calc.PageWrapper>
            <Calc.ContentWrapper style={{ height: 'auto' }}>
                <Calc.ContentContainer style={{ width: 'auto', margin: '0px' }}>
                    <SignUpContainer>
                        <TextContainer>
                            <SignUpTitle>Sign Up</SignUpTitle>
                            <CustomHeading>Join the companies tackling their digital emissions.</CustomHeading>
                            <SignUpParagraph>
                                Get precise data on your website’s carbon footprint with the Digital Scope SDK.
                            </SignUpParagraph>
                        </TextContainer>

                        <FormWrapper onSubmit={handleSubmit(submitData)} style={{ textAlign: 'left', color: '#000' }}>
                            <InputWrapper>
                                <RequiredLabel>Full Name</RequiredLabel>
                                <DefaultInputBox
                                    {...register('name')}
                                    className={errors.name ? 'error' : isSubmitted ? 'success' : ''}
                                    placeholder={'John Smith'}
                                />
                                <ErrorText stateType={errors.name ? 'error' : ''} message={errors.name?.message} />
                            </InputWrapper>

                            <InputWrapper>
                                <RequiredLabel>Email Address</RequiredLabel>
                                <DefaultInputBox
                                    {...register('email')}
                                    className={errors.email ? 'error' : isSubmitted ? 'success' : ''}
                                    placeholder={'john@smith.com'}
                                />
                                <ErrorText stateType={errors.email ? 'error' : ''} message={errors.email?.message} />
                            </InputWrapper>

                            <InputWrapper>
                                <RequiredLabel>Company</RequiredLabel>
                                <DefaultInputBox
                                    {...register('company')}
                                    className={errors.company ? 'error' : isSubmitted ? 'success' : ''}
                                    placeholder={'Acme Corporation'}
                                />
                                <ErrorText
                                    stateType={errors.company ? 'error' : ''}
                                    message={errors.company?.message}
                                />
                            </InputWrapper>

                            <InputWrapper>
                                <RequiredLabel>Website</RequiredLabel>
                                <DefaultInputBox
                                    {...register('website')}
                                    className={errors.website ? 'error' : isSubmitted ? 'success' : ''}
                                    placeholder={'www.acmecorporation.com'}
                                />
                                <InputInstructionWrapper style={{ marginTop: '8px' }}>
                                    Please submit the base URL of the website you want to install the SDK on
                                </InputInstructionWrapper>
                                <ErrorText
                                    stateType={errors.website ? 'error' : ''}
                                    message={errors.website?.message}
                                />
                            </InputWrapper>

                            <InputWrapper>
                                <FileUploadContainer>
                                    <DefaultLabel>Upload Your Logo</DefaultLabel>
                                    <FileUploadButtonContainer>
                                        <SmallBlackButton
                                            style={{ width: '152px' }}
                                            type='button'
                                            onClick={handleClick}
                                        >
                                            Choose File
                                            <input
                                                hidden
                                                type='file'
                                                name='file'
                                                accept='image/jpeg, image/png'
                                                ref={inputRef}
                                                onChange={handleFileUpload}
                                            />
                                        </SmallBlackButton>{' '}
                                        {logoFile && <Paragraph16>{logoFile.name}</Paragraph16>}
                                    </FileUploadButtonContainer>
                                    <InputInstructionWrapper>
                                        Upload a logo file (min. 128px x 128px) so we can customize your dashboard.
                                    </InputInstructionWrapper>
                                </FileUploadContainer>
                                <ErrorText
                                    stateType={fileUploadError ? 'error' : ''}
                                    message={'Please upload an image that is at least 128px x 128px'}
                                />
                            </InputWrapper>

                            <SubmitButtonContainer>
                                <CustomBigLakeButton type='submit'>Sign Up</CustomBigLakeButton>
                            </SubmitButtonContainer>
                        </FormWrapper>
                    </SignUpContainer>
                </Calc.ContentContainer>
            </Calc.ContentWrapper>
        </Calc.MainPageWrapper>
    )
}

export default SignUpForm
