import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

/**
 * Link and header link styling components
 */

export const PlainLink = styled(Link)`
    font-family: Manrope-SemiBold;
    font-size: 18px;
    line-height: 32px;
    text-align: left;
    color: #000000;
    text-decoration: none;
    border-bottom: 2px solid #5177b8;
    &:hover {
        color: #5177b8;
        text-decoration: none;
        border-bottom: 2px solid #5177b8;
    }
`

export const HeaderLink = styled(Link)`
    font-family: ${(props) => props.theme.fonts.manropeSemiBold};
    font-size: ${(props) => props.theme.typography.allCaps14.size};
    font-weight: ${(props) => props.theme.typography.allCaps14.weight};
    line-height: ${(props) => props.theme.typography.allCaps14.lineheight};
    letter-spacing: ${(props) => props.theme.typography.allCaps14.letterSpacing};
    text-transform: uppercase;
    color: #000000;
    text-decoration: none;
    &:hover {
        padding-top: 2px;
        color: #5177b8;
        text-decoration: none;
        border-bottom: 2px solid #5177b8;
        border-radius: 1px;
    }
`
