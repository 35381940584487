export const funFactData = [
    {
        funFact:
            'The internet produces 4% of the world’s greenhouse gas emissions, roughly equivalent to the aviation industry’s carbon emissions.',
        sourceLink:
            'https://www.google.com/url?q=https://theshiftproject.org/en/article/unsustainable-use-online-video/&sa=D&source=docs&ust=1665600146709971&usg=AOvVaw3LOJq16tI9U5NFq2et-fT1',
        sourceText: 'The Shift Project',
    },
    {
        funFact: 'The data centers behind the internet consume about 3% of the world’s electricity.',
        sourceLink:
            'https://en.reset.org/our-digital-carbon-footprint-environmental-impact-living-life-online-12272019/',
        sourceText: 'Reset - Digital for Good',
    },
    {
        funFact: 'The internet carbon emissions are expected to double by 2025, totaling 8% of the world’s emissions.',
        sourceLink: 'https://www.climatecare.org/resources/news/infographic-carbon-footprint-internet/',
        sourceText: 'Climate Care',
    },
    {
        funFact:
            'A single bitcoin transaction consumes 819kWh, the same amount of energy could run a standard refrigerator for 8 months. ',
        sourceLink:
            'https://en.reset.org/our-digital-carbon-footprint-environmental-impact-living-life-online-12272019/',
        sourceText: 'Reset - Digital for Good',
    },
    {
        funFact:
            'Video streaming, the biggest CO₂ culprit in online activity, has a larger annual carbon footprint than Spain.',
        sourceLink:
            'https://en.reset.org/our-digital-carbon-footprint-environmental-impact-living-life-online-12272019/',
        sourceText: 'Reset - Digital for Good',
    },
    {
        funFact:
            'Using a 200GB bandwidth per month produces nearly 3 tons of CO₂ annually. For reference, a car produces an annual average of 4.6 tons of CO₂.',
        sourceLink: 'https://theshiftproject.org/en/lean-ict-2/',
        sourceText: 'Calculated using 1-byte model',
    },
    {
        funFact:
            'Heat recycling? Sweden relies on a system that feeds waste heat from data centers into residential infrastructure, heating apartments, and buildings.',
        sourceLink:
            'https://en.reset.org/our-digital-carbon-footprint-environmental-impact-living-life-online-12272019/',
        sourceText: 'Reset - Digital for Good',
    },
]

export const funFactLoadingTitle = [
    { name: 'Calculating Results ...' },
    { name: 'Almost there ...' },
    {
        name: 'The calculation is taking longer than usual due to the large size of this webpage. Hold on a little longer ...',
    },
]
