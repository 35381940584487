/* eslint-disable no-console */
import leftIcon from '../../assets/icons/Icon-right-half.svg'
import rightIcon from '../../assets/icons/Icon-left-half.svg'
import { AllCaps12, AllCaps16 } from 'styles/typography'
import { Checkbox } from 'components/atoms/checkbox'
import InfoLogo from '../../assets/icons/info_outline.svg'
import { Pointer } from 'components/atoms/tooltip'
import { MediaInputBox, InputWrapper, ErrorText } from 'components/atoms/formInput'
import { DefaultLabel, RequiredLabel } from 'components/atoms/label'
import { useForm } from 'react-hook-form'
import { useEffect, useRef, useState } from 'react'
import * as Calc from './calculatorComponents'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { CalculatorHowTo } from './calculatorHowTo'
import Result from 'pages/Results'
import Info from 'components/info/info'
import { NoResult } from 'components/results/noResult'
import LoadingAnimation from 'components/animation/loadingAnimation'
import { CalculateMessage } from './calculateMessage'
import Error404 from 'pages/Error404'
import { addDelay } from 'utils/addDelay'
import config from 'config.json'

export const CalculatorInputSection = (props: any) => {
    const reportId = props.reportId
    // State that result page is from direct url or on button click
    const [resultOnClick, setResultOnClick] = useState(false)

    // Input fields of the calculator
    interface CalForm {
        url: string
        pageViews?: number
    }

    interface ReportProps {
        version?: number
        url?: string
        source?: string
        destination?: string
        numberOfHops?: number
        monthlyVisitors?: number
        greenHosting?: boolean
        renewableEnergyPercent?: number
        save?: boolean
    }

    // Ref that tracks the value of the checkbox
    const isChecked = useRef(false)
    let urlError
    let pageError

    // Ref that scrolls to results page
    const scrollRef = useRef<any>()

    // Regular expression for valid URLs
    // eslint-disable-next-line no-useless-escape
    const urlRegex = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/

    // Validations rules
    const calculatorValidation = Yup.object().shape({
        url: Yup.string().required('Please input a web page address').matches(urlRegex, 'Please input a valid url'),
        pageViews: Yup.string()
            .matches(/^[0-9]*$/, 'Please input a positive number')
            .transform((value) => (value === '' ? undefined : value.toString().replace(/,/g, ''))),
    })

    const {
        register, // method to register user input
        handleSubmit, // method to handle submission of user input
        formState: { errors }, // contains error information
    } = useForm<CalForm>({
        mode: 'onSubmit',
        resolver: yupResolver(calculatorValidation),
    })

    // State that gets and sets the report data
    const [reportData, setReportData] = useState(null)

    // State to register the report input before sending the POST request
    const [reportInput, setReportInput] = useState<ReportProps>({})

    // State to show the results
    const [isSubmitted, setIsSubmitted] = React.useState(false)

    // State to cause rerender of Result component
    const [rerender, setRerender] = React.useState(false)

    const [spinner, setSpinner] = useState(false)

    // If submit button is clicked, set success to 'success'
    const [success, setSuccess] = useState('')

    // State to check for valid data after calculation
    const [validData, setValidData] = React.useState(false)

    // check for shared report or not
    const [sharedReport, setSharedReport] = useState(false)

    const submitAttempt = () => {
        scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        setSuccess('success')
    }

    // Function to navigate to a certain page
    const navigate = useNavigate()

    // Function to change the path that corresponds to the input domian, and navigate to it
    const routeChange = (id: string) => {
        const path = `/calculator/${id}`
        navigate(path)
    }

    // Function to scroll to top on refresh
    window.onbeforeunload = function () {
        window.scrollTo(0, 0)
    }

    // if there is a uuid in the url and directly hit on the tab then it will call the fetchData method and call the api to get single report
    useEffect(() => {
        if (reportId && !resultOnClick) {
            setSharedReport(true)
            fetchData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function fetchData() {
        setSpinner(true)
        await addDelay(2000)
        try {
            // TO-DO: Extract base url into a config file
            await fetch(`${config.apiUrl}/report/${reportId}`, {
                method: 'GET',
            })
                .then((response) => response.json())
                .then((responseData) => setReportData(responseData))
                .then(function () {
                    setValidData(true)
                })
        } catch (error) {
            console.error(error)
        }
        setIsSubmitted(true)
        setSpinner(false)
    }

    async function RetreiveData(data: CalForm) {
        setSpinner(true)
        setIsSubmitted(false)
        setValidData(false)
        // If no value is given for the monthly page views field, the default value 10000 is used
        if (data.pageViews === undefined) {
            data.pageViews = 10000
        }

        const report = {
            version: 2.0,
            url: data.url,
            numberOfHops: 12, //TO-DO: Get number of hops between source and destination
            monthlyVisitors: data.pageViews,
            greenHosting: isChecked.current,
            save: true,
        }

        setReportInput(report)
        await addDelay(2000)
        try {
            // TO-DO: Extract base url into a config file
            await fetch(`${config.apiUrl}/report/`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(report),
            })
                .then((response) => response.json())
                .then((responseData) => {
                    setReportData(responseData)
                    routeChange(responseData.reportId)
                })
                .then(function () {
                    setValidData(true)
                })
        } catch (error) {
            console.error(error)
        }
        setSpinner(false)
        setIsSubmitted(true)
        setRerender(!rerender)
        setResultOnClick(true)
        scrollRef.current.scrollIntoView()
    }

    // Sets urlError, and pageError to true respectively if there is a url, or pageViews error in errors object
    if (errors.url) {
        urlError = true
    }
    if (errors.pageViews) {
        pageError = true
    }

    const numberChange = (e: any) => {
        let { value } = e.target
        value = parseInt(value.toString().replace(/,/g, ''))

        if (!isNaN(value)) {
            setReportInput({ ...reportInput, monthlyVisitors: value })
        } else if (e.target.value.length == 0) {
            setReportInput({ ...reportInput, monthlyVisitors: undefined })
        }
    }

    return (
        <Calc.MainPageWrapper>
            {((reportId == '' && !resultOnClick) ||
                (resultOnClick && reportId != '') ||
                (reportId == '' && resultOnClick)) && (
                <>
                    <Calc.PageWrapper>
                        <Calc.HomeWrapper>
                            <Calc.LeftImageWrapper src={leftIcon} alt='logo' />
                            <Calc.RightImageWrapper src={rightIcon} alt='logo' />
                        </Calc.HomeWrapper>
                    </Calc.PageWrapper>
                    <Calc.ContentWrapper>
                        <Calc.ContentContainer>
                            <Calc.TextContainer>
                                <AllCaps16>Calculator</AllCaps16>
                                <Calc.HomeHeadings>How much CO2 is your web page producing?</Calc.HomeHeadings>
                            </Calc.TextContainer>
                            <Calc.FormContainer onSubmit={handleSubmit(RetreiveData)}>
                                <InputWrapper>
                                    <RequiredLabel>Web page url</RequiredLabel>
                                    <MediaInputBox
                                        className={urlError ? 'error' : success}
                                        {...register('url')}
                                        placeholder='www.acme.com'
                                    />
                                    <ErrorText stateType={urlError ? 'error' : success} message={errors.url?.message} />
                                </InputWrapper>
                                <Calc.ViewsContainer>
                                    <Calc.ViewsInput>
                                        <InputWrapper>
                                            <DefaultLabel>Monthly page views (Optional)</DefaultLabel>
                                            <MediaInputBox
                                                className={pageError ? 'error' : success}
                                                {...register('pageViews')}
                                                placeholder='10,000'
                                                type='text'
                                                value={reportInput.monthlyVisitors
                                                    ?.toString()
                                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                onChange={(e) => numberChange(e)}
                                            />
                                            <ErrorText
                                                stateType={pageError ? 'error' : success}
                                                message={errors.pageViews?.message}
                                            />
                                        </InputWrapper>
                                    </Calc.ViewsInput>
                                    <Calc.GreenHostingContainer>
                                        <Calc.OptionalContainer>
                                            <AllCaps12>Green Hosting (Optional)</AllCaps12>
                                            <Pointer
                                                direction={'down'}
                                                message={
                                                    'Green hosting means the data centers your site uses are powered by green technology.'
                                                }
                                            >
                                                <img src={InfoLogo} alt='logo' />
                                            </Pointer>
                                        </Calc.OptionalContainer>
                                        <Checkbox
                                            label={'Yes'}
                                            onClick={(value: boolean) => (isChecked.current = value)}
                                        />
                                    </Calc.GreenHostingContainer>
                                </Calc.ViewsContainer>
                                <Calc.ButtonContainer>
                                    <Calc.CalculatorBlackButton onClick={submitAttempt}>
                                        Calculate
                                    </Calc.CalculatorBlackButton>
                                </Calc.ButtonContainer>
                            </Calc.FormContainer>
                        </Calc.ContentContainer>
                    </Calc.ContentWrapper>
                </>
            )}
            <div ref={scrollRef}></div>
            {spinner && <LoadingAnimation />}
            {reportId != '' && !resultOnClick && <CalculateMessage />}

            {/* If the data is valid and reportData is not empty object then it will rendor the Result component */}
            {validData && reportData ? (
                <Result
                    data={reportData}
                    isSubmitted={isSubmitted}
                    key={rerender}
                    reportInput={reportInput}
                    sharedReport={sharedReport}
                />
            ) : (isSubmitted && reportId != '') || (isSubmitted && resultOnClick) ? (
                resultOnClick ? (
                    <NoResult />
                ) : (
                    <Error404 />
                )
            ) : (
                <CalculatorHowTo />
            )}
            {validData && reportData ? <Info /> : null}
        </Calc.MainPageWrapper>
    )
}
