import styled from '@emotion/styled'
import { HeadingsJumbo, Paragraph18 } from 'styles/typography'
import { BigWhiteButton } from 'components/atoms/button'
import { goToTop, SimpleLink } from 'components/navbar'
import { HomeBackground } from './homeBackground'

//wrapper for indexing
const PageWrapper = styled.div`
    position: relative;
`
//wrapper for flex box
const ContentWrapper = styled.div`
    display: flex;
    height: ${(props) => props.theme.home.height.desktop};
    justify-content: center;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        height: ${(props) => props.theme.home.height.tablet};
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        height: ${(props) => props.theme.home.height.mobile};
    }
`
//wrapper for content margin
const TextContainer = styled.div`
    padding: ${(props) => props.theme.spacing.xxl[2]} 0px;
    width: ${(props) => props.theme.home.width.desktopContainer};
    display: flex;
    flex-direction: column;
    position: absoulute;
    z-index: 5;
    align-items: center;
    justify-content: space-between;
    color: ${(props) => props.theme.primaryColor.lake[75]};
    gap: ${(props) => props.theme.spacing.gap};
    text-align: center;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        padding: unset;
        margin: ${(props) => props.theme.spacing.xxl.default} ${(props) => props.theme.spacing.s};
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        width: ${(props) => props.theme.home.width.mobileContainer};
    }
`
//wrapper for button sizing
const HomeWhiteButton = styled(BigWhiteButton)`
    padding: unset;
    width: ${(props) => props.theme.home.width.desktopButton};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        width: ${(props) => props.theme.home.width.tabletButton};
        height: ${(props) => props.theme.home.height.tabletButton};
        font-size: ${(props) => props.theme.typography.allCaps14.size};
        font-weight: ${(props) => props.theme.typography.allCaps14.weight};
        line-height: ${(props) => props.theme.typography.allCaps14.lineheight};
        letter-spacing: ${(props) => props.theme.typography.allCaps14.letterSpacing};
    }
`
//wrapper for heading style
const HomeHeadings = styled(HeadingsJumbo)`
    color: ${(props) => props.theme.primaryColor.lake[50]};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        font-family: ${(props) => props.theme.typography.headingsH1.fontFamily};
        font-size: ${(props) => props.theme.typography.headingsH1.size};
        font-weight: ${(props) => props.theme.typography.headingsH1.weight};
        line-height: ${(props) => props.theme.typography.headingsH1.lineheight};
        letter-spacing: ${(props) => props.theme.typography.headingsH1.letterSpacing};
    }
`
export const ContentComponent = () => (
    <PageWrapper>
        <HomeBackground />
        <ContentWrapper>
            <TextContainer>
                <HomeHeadings>
                    Be a digital
                    <div>
                        <span style={{ color: 'white' }}> net-zero </span>leader
                    </div>
                </HomeHeadings>
                <Paragraph18 style={{ color: 'white' }}>
                    We help companies calculate how much CO2 their digital assets produce, supplying the data they need
                    to take action on Scope 3 emissions.
                </Paragraph18>
                <SimpleLink to='/calculator' onClick={goToTop}>
                    <HomeWhiteButton>Calculate your impact</HomeWhiteButton>
                </SimpleLink>
            </TextContainer>
        </ContentWrapper>
    </PageWrapper>
)
