import styled from '@emotion/styled'
import { useState } from 'react'
import checkmark from '../../assets/icons/check.svg'
import { Paragraph16 } from '../../styles/typography'

// Styling for checkbox
const StyledCheckbox = styled.div`
    box-sizing: border-box;
    width: ${(props) => props.theme.form.padding};
    height: ${(props) => props.theme.form.padding};
    border-radius: ${(props) => props.theme.form.borderRadius};
    margin-right: ${(props) => props.theme.spacing.xxs};
    border: solid ${(props) => props.theme.input.border} ${(props) => props.theme.primaryColor.black[25]};
    &.default:hover {
        border-color: ${(props) => props.theme.primaryColor.black[50]};
    }
    &.checked {
        background-color: ${(props) => props.theme.primaryColor.black[100]};
        border: none;
    }
    &.checked:hover {
        background-color: ${(props) => props.theme.primaryColor.black[50]};
    }
`
// Image that does not display if given className hide
const StyledImg = styled.img`
    &.hide {
        display: none;
    }
`
// Wrapper for CheckBox
const CheckboxWrapper = styled.div`
    display: flex;
`
/*
 * Checkbox component
 * Display custom label by passing in your text through the label prop
 */
export const Checkbox = (props: any) => {
    const [checked, setChecked] = useState(true)
    return (
        <CheckboxWrapper>
            <StyledCheckbox
                className={checked ? 'default' : 'checked'}
                onClick={() => {
                    setChecked(!checked)
                    props.onClick(checked)
                }}
            >
                <StyledImg className={checked ? 'hide' : ''} src={checkmark}></StyledImg>
            </StyledCheckbox>
            <Paragraph16>{props.label}</Paragraph16>
        </CheckboxWrapper>
    )
}
