import { useRef, useState } from 'react'
import styled from '@emotion/styled'
import expandMore from '../../assets/icons/expand_more.svg'
import close from '../../assets/icons/close.svg'
import { Subheading20 } from '../../styles/typography'

// The Form Dropdown component

// Wrapper for the heading
const HeadingWrapper = styled(Subheading20)`
    display: flex;
    text-align: center;
    box-sizing: border-box;
    width: 100%;
    justify-content: space-between;
    scroll-margin: 72px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05);
    background-color: ${(props) => props.theme.primaryColor.white};
    padding: ${(props) => props.theme.spacing.s} ${(props) => props.theme.spacing.l};
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        padding: ${(props) => props.theme.spacing.s};
    }
`

// Wrapper for the dropdown content
const ContentWrapper = styled.div`
    position: relative;
    padding: 0px ${(props) => props.theme.spacing.l};
    background-color: ${(props) => props.theme.primaryColor.white};
    overflow-y: scroll;
    height: 80vh;
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        padding: 0px ${(props) => props.theme.spacing.s};
    }
`

/**
 * Display custom heading and content through the heading and content props
 * e.g. <FormDropdown heading={'Sample Question'} content={'Sample Answer'} />
 */

const FormDropdown = (props: any) => {
    // Ref that scrolls to the section
    const scrollRef = useRef<any>()
    const [clicked, setClicked] = useState(false)
    const clickArrow = () => {
        scrollRef.current.scrollIntoView({ behavior: 'smooth' })
        setClicked(!clicked)
    }
    return (
        <div>
            <HeadingWrapper ref={scrollRef} onClick={clickArrow}>
                <div>{props.heading}</div>
                <span>{clicked ? <img src={close} alt='close' /> : <img src={expandMore} alt='expand more' />}</span>
            </HeadingWrapper>
            {clicked ? <ContentWrapper>{props.content}</ContentWrapper> : null}
        </div>
    )
}

export default FormDropdown
