import styled from '@emotion/styled'
import whiteLogo from '../assets/icons/logo-white.svg'
import instagram from '../assets/icons/instagram.svg'
import facebook from '../assets/icons/facebook.svg'
import twitter from '../assets/icons/twitter.svg'
import linkedin from '../assets/icons/linkedin.svg'
import { Paragraph14, AllCaps14 } from 'styles/typography'
import { Link } from 'react-router-dom'

// Container for footer components
const FooterMediaQuery = styled.div`
    height: ${(props) => props.theme.height.footer.desktop};
    background-color: ${(props) => props.theme.primaryColor.black[100]};
    display: flex;
    color: ${(props) => props.theme.primaryColor.white};
    padding: ${(props) => props.theme.spacing.padding.vertical} ${(props) => props.theme.spacing.xxl.default};
    justify-content: space-between;
    align-items: center;
    gap: ${(props) => props.theme.spacing.m};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        height: ${(props) => props.theme.height.footer.mobile};
        text-align: center;
        flex-direction: column;
        padding: ${(props) => props.theme.spacing.padding.vertical} ${(props) => props.theme.spacing.l};
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        padding: ${(props) => props.theme.spacing.padding.vertical} ${(props) => props.theme.spacing.s};
    }
`
// Styling for paragraph with project description
const StyledParagraph = styled(Paragraph14)`
    width: 252px;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        text-align: center;
        width: 288px;
        order: 2;
    }
`
// Container that holds social media icons and TermWrapper
const MediaContainer = styled.div`
    margin-left: 100px;
    order: 3;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        margin-left: 0px;
    }
`
// Margin styling for social media icons
const StyledImg = styled.img`
    margin-right: ${(props) => props.theme.spacing.xxs};
`
// Paragraph styling for terms and privacy text
const TermParagraph = styled(AllCaps14)`
    margin-top: 12px;
    text-align: center;
`

export const PlainLink = styled(Link)`
    text-decoration: none;
    border-bottom: 2px solid #5177b8;
    font-family: ${(props) => props.theme.typography.paragraph18.fontFamily};
    font-size: ${(props) => props.theme.typography.paragraph18.size};
    font-weight: ${(props) => props.theme.typography.paragraph18.weight};
    line-height: ${(props) => props.theme.typography.paragraph18.lineheight};
    letter-spacing: ${(props) => props.theme.typography.paragraph18.letterSpacing};
    color: ${(props) => props.theme.primaryColor.black[75]};
    &:hover {
        color: #5177b8;
        text-decoration: none;
        border-bottom: 2px solid #5177b8;
    }
`
export const SimpleLink = styled(Link)`
    text-decoration: none;
    &.hideMobile {
        @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
            display: none;
        }
    }
`

//constant to scroll page to top
export const goToTop = () => {
    window.scrollTo({
        top: 0,
    })
}

export const CustomFooter = () => (
    <FooterMediaQuery>
        <StyledParagraph>
            Digital Scope is a Redbrick project, built to help climate leaders gather accurate digital carbon emissions
            data.
        </StyledParagraph>
        <img src={whiteLogo} width='200' height='40' alt='second logo' />
        <MediaContainer>
            <a href='https://www.linkedin.com/company/digital-scope-inc/'>
                <StyledImg src={linkedin} alt='linkedin' />
            </a>
            <a href='https://www.facebook.com/DigitalScopeInc'>
                <StyledImg src={facebook} alt='facebook' />
            </a>
            <a href='https://twitter.com/digitalscopeinc'>
                <StyledImg src={twitter} alt='twitter' />
            </a>
            <a href='https://www.instagram.com/digital_scope_inc/'>
                <img src={instagram} alt='instagram' />
            </a>
            <TermParagraph>
                <SimpleLink style={{ color: '#fff' }} to='/terms-of-service' onClick={goToTop}>
                    TERMS
                </SimpleLink>{' '}
                |{' '}
                <SimpleLink style={{ color: '#fff' }} to='/privacy-policy' onClick={goToTop}>
                    PRIVACY
                </SimpleLink>
            </TermParagraph>
        </MediaContainer>
    </FooterMediaQuery>
)
