// Home Page
import { ContentComponent } from 'components/home/homeContent'
import { useEffect } from 'react'
import About from './About'
import Corporate from './Corporate'
import HowItWorks from './HowItWorks'

export default function Home() {
    useEffect(() => {
        const script = document.createElement('script')
        script.src = 'https://sdk.digitalscope.com/index-v2.js?app=DIGI'
        script.async = true
        script.type = 'text/javascript'
        document.body.appendChild(script)
    }, [])

    return (
        <div>
            <ContentComponent />
            <Corporate />
            <About />
            <HowItWorks />
        </div>
    )
}
