import { useState } from 'react'
import SignUpForm from 'components/signup/signupForm'
import { TakeAction } from 'components/signup/takeAction'
import HelpingEarth from 'components/signup/helpingEarth'
import ThankYou from 'components/signup/thankYou'

export default function SignUp() {
    const [isSuccessful, setIsSuccessful] = useState(false)

    return (
        <>
            {isSuccessful ? (
                <ThankYou />
            ) : (
                <>
                    <SignUpForm setIsSuccessful={setIsSuccessful} />
                    <HelpingEarth />
                    <TakeAction />
                </>
            )}
        </>
    )
}
