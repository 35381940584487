import styled from '@emotion/styled'
import sdkOutput from '../../assets/icons/sdk-output.svg'
import { goToTop, SimpleLink } from 'components/navbar'
import { SmallBlackButton } from 'components/atoms/button'

// The intro section for SDK

// Container for the whole section
const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${(props) => props.theme.primaryColor.white};
    padding: ${(props) => props.theme.spacing.xl} ${(props) => props.theme.spacing.xxl.default};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        padding: ${(props) => props.theme.spacing.xxl.default} ${(props) => props.theme.spacing.l};
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        padding-left: ${(props) => props.theme.spacing.s};
        padding-right: ${(props) => props.theme.spacing.s};
    }
`

// Container for the image and text
const ContentContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 1088px;
    gap: ${(props) => props.theme.spacing.xxl.default};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        gap: ${(props) => props.theme.spacing.xl};
        flex-direction: column;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        gap: ${(props) => props.theme.spacing.xxl.default};
    }
`

// Wrapper for the image
const ImageWrapper = styled.div`
    img {
        width: 528px;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        img {
            width: 312px;
        }
    }
`
// Warpper for the text
const TextWrapper = styled.div`
    width: 496px;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: ${(props) => props.theme.spacing.m};
    @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
        width: unset;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        align-items: center;
    }
    div {
        color: ${(props) => props.theme.primaryColor.black[100]};
        font-family: ${(props) => props.theme.typography.headingsH2.fontFamily};
        font-size: ${(props) => props.theme.typography.headingsH2.size};
        font-weight: ${(props) => props.theme.typography.headingsH2.weight};
        line-height: ${(props) => props.theme.typography.headingsH2.lineheight};
        letter-spacing: ${(props) => props.theme.typography.headingsH2.letterSpacing};
        @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
            font-family: ${(props) => props.theme.typography.headingsH3.fontFamily};
            font-size: ${(props) => props.theme.typography.headingsH3.size};
            font-weight: ${(props) => props.theme.typography.headingsH3.weight};
            line-height: ${(props) => props.theme.typography.headingsH3.lineheight};
            letter-spacing: ${(props) => props.theme.typography.headingsH3.letterSpacing};
        }
    }
    p {
        color: ${(props) => props.theme.primaryColor.black[75]};
        font-family: ${(props) => props.theme.typography.paragraph18.fontFamily};
        font-size: ${(props) => props.theme.typography.paragraph18.size};
        font-weight: ${(props) => props.theme.typography.paragraph18.weight};
        line-height: ${(props) => props.theme.typography.paragraph18.lineheight};
        letter-spacing: ${(props) => props.theme.typography.paragraph18.letterSpacing};
        margin: 0px;
    }
`

const SDKIntro = () => (
    <Container>
        <ContentContainer>
            <TextWrapper>
                <div>Powering action</div>
                <p>
                    Our team has developed an SDK for JavaScript which can be embedded into your site to provide
                    continual, integrated carbon footprint measurement.
                </p>
                <SimpleLink to='/contact' onClick={goToTop}>
                    <SmallBlackButton style={{ width: '168px', padding: 'unset' }}>Let’s Connect</SmallBlackButton>
                </SimpleLink>
            </TextWrapper>
            <ImageWrapper>{<img src={sdkOutput} alt='sdkoutput' />}</ImageWrapper>
        </ContentContainer>
    </Container>
)

export default SDKIntro
