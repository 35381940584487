import styled from '@emotion/styled'
import { HeadingsH2, Paragraph18, Subheading20 } from 'styles/typography'
import { SDKWrapper } from 'components/corporate/corporateContent'
import { goToTop, SimpleLink } from 'components/navbar'
import { SmallBlackButton } from 'components/atoms/button'
import { SdkAnimation } from 'components/atoms/animations'

//wrapper for the main margin and flex boxes
const ContentWrapper = styled.div`
    position: relative;
    margin: ${(props) => props.theme.spacing.xl} ${(props) => props.theme.spacing.l};
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${(props) => props.theme.spacing.xxl.default};
    align-items: center;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        margin: ${(props) => props.theme.spacing.xxl.default} ${(props) => props.theme.spacing.l};
        width: unset;
        gap: ${(props) => props.theme.spacing.l};
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        margin: ${(props) => props.theme.spacing.xxl.default} ${(props) => props.theme.spacing.s};
    }
`
//wrappers for prediction text and border
const InternetPredicitonWrapper = styled.div`
    justify-content: center;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1088px;
    border-top: ${(props) => props.theme.input.border} solid ${(props) => props.theme.primaryColor.black[100]};
    border-bottom: ${(props) => props.theme.input.border} solid ${(props) => props.theme.primaryColor.black[100]};
    color: ${(props) => props.theme.primaryColor.lake[75]};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        text-align: center;
        width: unset;
    }
`
const PredictionText = styled(Subheading20)`
    margin: ${(props) => props.theme.spacing.m} 0px;
    text-align: center;
`
//adjust button size
const HowToButton = styled(SmallBlackButton)`
    padding: unset;
    width: 205px;
    height: 40px;
`
//wrapper to adjust the bottom section placement
const BottomWrapper = styled.div`
    max-width: 1088px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: ${(props) => props.theme.spacing.xxl.default};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        flex-direction: column;
        gap: ${(props) => props.theme.spacing.xl};
        align-items: center;
    }
`
//wrappers for how-to text and button
const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: ${(props) => props.theme.spacing.l} 0px;
    gap: ${(props) => props.theme.spacing.m};
    width: ${(props) => props.theme.corporate.width.connectTextBox};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        width: unset;
        gap: ${(props) => props.theme.spacing.l};
        align-items: center;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        gap: ${(props) => props.theme.spacing.m};
    }
`
const QuestionWrapper = styled(HeadingsH2)`
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        text-align: center;
        font-family: ${(props) => props.theme.typography.headingsH3.fontFamily};
        font-size: ${(props) => props.theme.typography.headingsH3.size};
        font-weight: ${(props) => props.theme.typography.headingsH3.weight};
        line-height: ${(props) => props.theme.typography.headingsH3.lineheight};
        letter-spacing: ${(props) => props.theme.typography.headingsH3.letterSpacing};
    }
`
const HowToWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${(props) => props.theme.spacing.m};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        align-items: center;
        gap: ${(props) => props.theme.spacing.l};
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        gap: ${(props) => props.theme.spacing.m};
    }
`
const HowToTextBox = styled.div`
    color: ${(props) => props.theme.primaryColor.black[75]};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    }
`

export const CalculatorHowTo = () => (
    <ContentWrapper>
        <InternetPredicitonWrapper>
            <PredictionText>
                In 2022, the internet will emit over 1.6 billion tonnes of greenhouse gases.
            </PredictionText>
        </InternetPredicitonWrapper>
        <BottomWrapper>
            <TextContainer>
                <QuestionWrapper>How can a website emit carbon emissions?</QuestionWrapper>
                <HowToWrapper>
                    <HowToTextBox>
                        <Paragraph18>
                            Every search query, web page interaction, video view, like on social media – anything we do
                            online – creates carbon dioxide (CO2) emissions. These emissions are a byproduct of the
                            energy needed to power digital devices and data exchange across vast wireless networks.
                            <p />
                            We calculate how much unclean energy is consumed when you load a webpage. From that
                            estimate, we can determine how much CO2 that page is responsible for producing.
                        </Paragraph18>
                    </HowToTextBox>
                    <HowToButton>
                        <SimpleLink to='/how-it-works' style={{ color: 'white' }} onClick={goToTop}>
                            How we calculate
                        </SimpleLink>
                    </HowToButton>
                </HowToWrapper>
            </TextContainer>
            <SDKWrapper>
                <SdkAnimation />
            </SDKWrapper>
        </BottomWrapper>
    </ContentWrapper>
)
