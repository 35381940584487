import styled from '@emotion/styled'
import { HeadingsH1, Paragraph18 } from 'styles/typography'

//wrapper for indexing
const PageWrapper = styled.div`
    position: relative;
    background-color: ${(props) => props.theme.primaryColor.cloud[10]};
`
//wrapper for flex box
const ContentWrapper = styled.div`
    display: flex;
    justify-content: center;
`
//wrapper for content margin
const TextContainer = styled.div`
    padding: ${(props) => props.theme.spacing.xxl[2]} 0px;
    width: ${(props) => props.theme.home.width.desktopContainer};
    display: flex;
    flex-direction: column;
    position: absoulute;
    z-index: 5;
    align-items: center;
    justify-content: space-between;
    gap: ${(props) => props.theme.spacing.gap};
    text-align: center;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        padding: unset;
        margin: ${(props) => props.theme.spacing.xxl.default} ${(props) => props.theme.spacing.s};
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        width: ${(props) => props.theme.home.width.mobileContainer};
    }
`

export const NoResult = () => (
    <PageWrapper>
        <ContentWrapper>
            <TextContainer>
                <HeadingsH1>Oops!</HeadingsH1>
                <Paragraph18 style={{ color: '#444' }}>
                    We were unable to calculate the carbon emissions of this webpage.
                </Paragraph18>
            </TextContainer>
        </ContentWrapper>
    </PageWrapper>
)
