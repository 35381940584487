import styled from '@emotion/styled'
import { HeadingsJumbo, Paragraph18 } from 'styles/typography'
import { SmallBlackButton } from 'components/atoms/button'
import { Link } from 'react-router-dom'

// Error Page

const ContainerWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: auto;
    height: 100%;
`

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: ${(props) => props.theme.primaryColor.cloud[10]};
    padding: 256px 512px;
    height: 232px;
    width: 100%;
    gap: ${(props) => props.theme.spacing.gap};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        height: 232px;
        width: 100%;
        padding: 196px 176px;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        height: 100%;
        width: 100%;
        padding: ${(props) => props.theme.spacing.xl} ${(props) => props.theme.spacing.s};
    }
`
// Wrapper for the heading
const HeadingWarpper = styled(HeadingsJumbo)`
    text-align: center;
    width: 100%;
    color: ${(props) => props.theme.primaryColor.lake[75]};
    font-family: ${(props) => props.theme.fonts.manropeRegular};
    font-size: ${(props) => props.theme.typography.headingsJumbo.size};
    font-weight: 700;
    line-height: ${(props) => props.theme.typography.headingsJumbo.lineheight};
`

// Wrapper for the intro paragraph
const ErrorWarpper = styled(Paragraph18)`
    width: 100%;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        width: unset;
    }
    text-align: center;
    color: ${(props) => props.theme.primaryColor.black[75]};
    }
`

// Styling for button wrapper
const ButtonWrapper = styled(SmallBlackButton)`
    width: auto;
`
// Link with no styling that disappears when the page is mobile screen size
export const SimpleLink = styled(Link)`
    text-decoration: none;
    &.hideMobile {
        @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
            display: none;
        }
    }
`

const Error404 = () => (
    <ContainerWrapper>
        <Container>
            <HeadingWarpper>404</HeadingWarpper>
            <ErrorWarpper>Sorry, the page you are looking for does not exist.</ErrorWarpper>
            <ButtonWrapper>
                <SimpleLink to='/calculator' style={{ color: 'white' }}>
                    Back to Home
                </SimpleLink>
            </ButtonWrapper>
        </Container>
    </ContainerWrapper>
)

export default Error404
