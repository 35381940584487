import styled from '@emotion/styled'
import { HeadingsH1 } from 'styles/typography'
import { Link } from 'react-router-dom'

// Privacy Policy Page

const ContainerWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
`

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: ${(props) => props.theme.primaryColor.cloud[10]};
    padding: ${(props) => props.theme.spacing.xl} 176px;
    gap: ${(props) => props.theme.spacing.s};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        padding: ${(props) => props.theme.spacing.xxl.default} ${(props) => props.theme.spacing.l};
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        padding-left: ${(props) => props.theme.spacing.s};
        padding-right: ${(props) => props.theme.spacing.s};
    }
`
// Wrapper for the heading
const HeadingWarpper = styled(HeadingsH1)`
    width: 100%;
    max-width: 1440px;
    font-size: ${(props) => props.theme.typography.headingsH2.size};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        font-family: ${(props) => props.theme.typography.headingsH2.fontFamily};
        font-size: ${(props) => props.theme.typography.headingsH2.size};
        font-weight: ${(props) => props.theme.typography.headingsH2.weight};
        line-height: ${(props) => props.theme.typography.headingsH2.lineheight};
        letter-spacing: ${(props) => props.theme.typography.headingsH2.letterSpacing};
    }
`

const ParagraphWrapper = styled.div`
    width: 100%;
    max-width: 1440px;
    color: ${(props) => props.theme.primaryColor.black[75]};
    font-family: ${(props) => props.theme.typography.paragraph18.fontFamily};
    font-size: ${(props) => props.theme.typography.paragraph18.size};
    font-weight: ${(props) => props.theme.typography.paragraph18.weight};
    line-height: ${(props) => props.theme.typography.paragraph18.lineheight};
    letter-spacing: ${(props) => props.theme.typography.paragraph18.letterSpacing};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        width: unset;
    }
`

// Link with no styling that disappears when the page is mobile screen size
export const PlainLink = styled(Link)`
    text-decoration: none;
    border-bottom: 2px solid #5177b8;
    font-family: ${(props) => props.theme.typography.paragraph18.fontFamily};
    font-size: ${(props) => props.theme.typography.paragraph18.size};
    font-weight: ${(props) => props.theme.typography.paragraph18.weight};
    line-height: ${(props) => props.theme.typography.paragraph18.lineheight};
    letter-spacing: ${(props) => props.theme.typography.paragraph18.letterSpacing};
    color: ${(props) => props.theme.primaryColor.black[75]};
    &:hover {
        color: #5177b8;
        text-decoration: none;
        border-bottom: 2px solid #5177b8;
    }
`

//constant to scroll page to top
export const goToTop = () => {
    window.scrollTo({
        top: 0,
    })
}

const TermsOfService = () => (
    <ContainerWrapper>
        <Container>
            <HeadingWarpper
                style={{ fontSize: '56px', display: 'flex', justifyContent: 'center', textAlign: 'center' }}
            >
                Terms Of Service
            </HeadingWarpper>
            <ParagraphWrapper>
                Effective as of OCTOBER 1, 2022. <br /> <br />
                Welcome to digitalscope.com (the “Site”). By visiting this Site and accessing any content or using any
                of the services, technology, software, tools, products, and any related materials, being made available
                through the Site (collectively, the “Services”), you agree to the following terms and conditions. <br />{' '}
                <br /> This Site is an Internet property of Redbrick Technologies Inc. and its affiliated companies
                (“Redbrick”, “we” or “us”). The Services are being made available to you by Redbrick. Redbrick is
                granting you a limited right to access, view, and use certain published content and to access, view, and
                use certain Services that are being made available to you through the Site. The Privacy Policy (“Privacy
                Policy”) and any and all other applicable Redbrick operating rules, policies, and other supplemental
                terms and conditions or documents that may be published from time to time, are expressly incorporated
                here by reference (collectively, the “Agreement”). Please review the complete terms and conditions of
                this Agreement carefully. You may not reproduce, modify, or re-distribute any Redbrick content,
                materials, or any of the Services, in whole or in part, and in any way, that are being made available to
                you on this Site unless otherwise authorized in writing by Redbrick. <br /> <br />
                <b>
                    IF YOU DO NOT AGREE TO THIS AGREEMENT IN ITS ENTIRETY, YOU ARE NOT AUTHORIZED TO ACCESS THIS SITE,
                    OR USE ANY OF THE SERVICES BEING MADE AVAILABLE TO YOU THROUGH THIS SITE, IN ANY MANNER OR FORM.
                </b>{' '}
                <br /> <br />
                <b>1. LICENSE – </b>Subject to the terms and conditions of this Agreement, Redbrick grants to you a
                limited, revocable, non-exclusive, non-transferable right and license, without the right to sublicense,
                to use the Services and any related materials in accordance with this Agreement and any other written
                agreement that you may have with Redbrick. These terms and conditions will also govern any Services
                upgrades and updates provided by Redbrick that replace and/or supplement the original Services, unless
                such upgrades and/or updates are accompanied by a separate license, in which case the terms of that
                Services license agreement will govern. You are being granted a right and license to use the Services
                and any related materials. Redbrick does not transfer title of the Services and any related materials to
                you. This Agreement is a legally binding agreement between you Redbrick. If you do not agree to the
                terms and conditions of this Agreement, you are required to immediately cease using the Services and any
                related materials. Redbrick reserves the right to terminate this Agreement and the licenses granted
                herein in the event that you breach any term or condition of this Agreement. <br /> <br />
                <b>2. USE OF THE SITE AND SERVICES – </b>Subject to the terms and conditions of this Agreement, Redbrick
                grants to you a limited, revocable, non-exclusive, non-transferable right and license, without the right
                to sublicense, to use the Services and any related materials in accordance with this Agreement and any
                other written agreement that you may have with Redbrick. These terms and conditions will also govern any
                Services upgrades and updates provided by Redbrick that replace and/or supplement the original Services,
                unless such upgrades and/or updates are accompanied by a separate license, in which case the terms of
                that Services license agreement will govern. You are being granted a right and license to use the
                Services and any related materials. Redbrick does not transfer title of the Services and any related
                materials to you. This Agreement is a legally binding agreement between you Redbrick. If you do not
                agree to the terms and conditions of this Agreement, you are required to immediately cease using the
                Services and any related materials. Redbrick reserves the right to terminate this Agreement and the
                licenses granted herein in the event that you breach any term or condition of this Agreement. <br />{' '}
                <br />
                <b>3. SITE AND SERVICE AVAILABILITY – </b>Redbrick uses commercially reasonable efforts to ensure that
                the Site and the Service are available 24 hours a day 7 days a week. However, there will be occasions
                when the Site and/or the Services will be interrupted for maintenance, upgrades and emergency repairs or
                due to failure of telecommunications links and equipment that are beyond the control of Redbrick. Every
                reasonable step will be taken by us to minimize such disruption where it is within the reasonable
                control of Redbrick. You agree that Redbrick shall not be responsible or liable to you for (i) the
                deletion or failure to make available any content, services, products, and other communications
                maintained or transmitted through the Site and/or Services; or (ii) any modification, suspension or
                discontinuance of the Site and/or Services or products or related materials made available to you
                through the Site and/or Services. You acknowledge that Redbrick reserves the right, in its sole
                discretion, to limit your ability to access and use the Site and/or Services, products, related
                materials, or other resources or content made available on or through the Site and/or Services. Redbrick
                reserves the right to terminate, suspend, or limit certain features or functionality of the Site and/or
                Services and any related materials, products and/or services being made available to you through the
                Site and/or Services, in its sole discretion.
                <br /> <br />
                <b>4. CONDUCT – </b>You agree that you are responsible for your own conduct when accessing the Site and
                using the Services, and for any consequences thereof. You agree NOT to use the Site and/or the Services:
                (a) for any unlawful purposes; (b) to impersonate any person or entity, or otherwise to misrepresent
                your affiliation with a person or entity while using the Services, related materials, or products; (c)
                to collect, intercept, harvest, or store personal data about other visitors of the Site or users of the
                Services, related materials, or products, or to solicit or attempt to discover a user's personal data
                without the user's express knowledge and consent; and (d) to reproduce, duplicate, copy, or resell the
                Services, and related materials, or any content or products that are made available through the Site
                and/or Services, or any portion of thereof, unless otherwise authorized by Redbrick. You agree that
                monetary damages for a breach of or a threatened breach of any of the foregoing will not be adequate and
                that in the event you violate any term or condition of this Agreement, Redbrick shall be entitled to
                injunctive relief (including temporary and preliminary relief), liquidated damages, and any other
                remedies available at law.
                <br /> <br />
                <b>5. PRIVACY POLICY – </b>By visiting and accessing the Site and/or Services, you acknowledge and agree
                that Redbrick may access, preserve, and disclose any personal information collected by Redbrick if
                required to do so by law or in a good faith belief that such access, preservation, or disclosure is
                reasonably necessary to: (a) satisfy any applicable law, regulation, legal process or enforceable
                governmental request; (b) enforce the terms and conditions of this Agreement, including investigation of
                potential violations hereof; (c) detect, prevent, or otherwise address fraud, security or technical
                issues (including, without limitation, the filtering of spam); or (d) protect against imminent harm to
                the rights, property or safety of Redbrick, its Site visitors, users of the Services, or the public as
                required or permitted by law. More information on Redbrick’s Privacy Policy can be reviewed at{' '}
                <PlainLink to='/privacy-policy' onClick={goToTop}>
                    digitalscope.com/privacy-policy.
                </PlainLink>
                <br /> <br />
                <b>6. COPYRIGHT RESTRICTION – </b>The Site, the Services, and any content, products and/or services
                being made available to you through the Site and/or Services, may contain copyrighted material, trade
                secrets and other proprietary and confidential material that is protected by applicable intellectual
                property and other laws and treaties. Redbrick, or the applicable third-party licensor(s), retain all
                patent, trademarks, and copyright to any content, products, and services published on the Site. Subject
                to the terms and conditions of this Agreement, Redbrick grants to you a limited, non-transferable and
                non-exclusive right to access and use the Site, the Services, and any products, services, and/or related
                materials being made available to you through the Site and Services. You shall not directly, and shall
                not attempt to allow or assist any third party to, modify, reverse engineer, disassemble or decompile
                the Site, the Services, or any of the products, services, and/or related materials being made available
                to you through the Site, nor shall you create any derivative works or other works that are based upon or
                derived from the Site content, the Services, or any of the products, services, and/or related materials
                being made available to you through the Site and/or the Services, in whole or in part. Redbrick, and its
                applicable licensors, retain sole and exclusive ownership of all right, title and interest in and to the
                content provided through the Site and/or Services, and all intellectual property rights relating thereto
                including without limitation Redbrick’s name, any product or service names, any Redbrick logos and
                graphic files that represents the Site content, the Services, any related materials, and any products
                and/or services. You further agree not to remove, obscure, or alter any Redbrick or any third-party
                copyright notices, trademarks, or other proprietary rights notices affixed to or contained within or
                accessed in conjunction with or through the Site and/or Services. You acknowledge and agree that third
                parties, may own right, title and interest in and to the content, products, and services hosted on the
                Site or otherwise presented to you through the Site and/or Services, and that such content may be
                protected by applicable intellectual property laws and other laws and treaties. Copyright law and
                international copyright treaty provisions protect all parts of the Site, the Services, and any related
                materials. No program, code, part, image, or text may be copied, distributed, or used in any way by you
                except as intended within the bounds of this Agreement. All rights not expressly granted hereunder are
                reserved for Redbrick or its applicable licensors.
                <br /> <br />
                <b>7. THIRD-PARTY OPEN-SOURCE SOFTWARE LICENSE - </b>The Services may include third-party software
                components and technologies. Such third-party software may incorporate certain open-source software and
                code, which is being licensed to you and is subject to the licensing terms and conditions of each
                applicable third-party provider. You are permitted to use and may be permitted to distribute, free of
                charge, certain source code of the features and functionality of the Services under the applicable
                public licenses. The applicable open-source licenses may permit you to copy, run, modify, and/or
                redistribute the open-source components, subject to certain restrictions, described in the terms of each
                applicable license. You are responsible for reviewing and complying with each applicable license. The
                terms and conditions of this Agreement are not intended to limit your rights under the applicable
                third-party open-source licenses, nor grant you any rights that supersede the terms and conditions of
                any particular third-party open-source licenses. You acknowledge and agree that in the event of any
                conflict or discrepancy between the terms and conditions set out in this Agreement and the applicable
                third-party open-source license, the terms of the third-party open-source license shall govern and
                prevail. <br /> <br />
                The Services may utilize the following third-party open-source software code and technologies, and the
                applicable licenses can be viewed at{' '}
                <PlainLink to='/resources/third-party-licenses' onClick={goToTop}>
                    digitalscope.com/resources/third-party-licenses.{' '}
                </PlainLink>
                <br /> <br />
                <b>8. ADVERTISING AND LINKS – </b>Redbrick may display third party advertisements and promotions on the
                Site, through the Services, or through the products and/or services that have been made available to you
                through the Site and/or Services. The manner, mode and extent of advertising by Redbrick on the Site,
                through the Services, or through any related materials, products and/or services are subject to change
                at any time. You agree that Redbrick shall not be responsible or liable for any loss or damage of any
                sort incurred by you as a result of your dealings with such third parties. Redbrick may provide, or
                third parties may provide, links to other Internet sites or resources. Because Redbrick has no control
                over such sites and resources, you acknowledge and agree that Redbrick is not responsible for the
                availability of such external websites, resources, content or policies that govern the use of those
                websites. Furthermore, Redbrick does not endorse and is not responsible or liable for any content,
                advertising, products, services, or other materials on or being made available from such sites or
                resources. You further acknowledge and agree that Redbrick shall not be responsible or liable, directly
                or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or
                reliance on any such advertising content, goods, products, and/or services available on or through any
                such site or resource, nor will Redbrick accept any responsibility for any viruses, worms, Trojan horses
                or other forms of destructive malware that may infect your computer systems as a result of your use of
                any third party websites.
                <br /> <br />
                <b>9. CONSENT TO USE OF DATA – </b>You agree that Redbrick may collect and use information transmitted
                by you through the Services and/or gathered in any manner as part of the Services provided to you, if
                any. Redbrick may also use any information collected to improve and enhance the Services and to provide
                notices to you which may be of use or interest to you. <br /> <br />
                <b>10. DISCLAIMER OF WARRANTIES - </b>STHE SITE, THE SERVICES, AND ANY RELATED MATERIALS, PRODUCTS
                AND/OR SERVICES BEING MADE AVAILABLE TO YOU THROUGH THE SITE AND/OR SERVICES, ARE PROVIDED ON AN “AS IS”
                AND “AS AVAILABLE” BASIS WITH NO WARRANTIES EXCEPT AS OTHERWISE SPECIFIED HEREIN. TO THE FULLEST EXTENT
                PERMITTED BY APPLICABLE LAW, REDBRICK MAKES NO WARRANTIES, EXPRESS OR IMPLIED, ORAL OR WRITTEN,
                INCLUDING WITHOUT LIMITATION THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
                PURPOSE. REDBRICK DOES NOT WARRANT THAT (I) THE SITE, THE SERVICES, OR ANY PUBLISHED CONTENT, RELATED
                MATERIALS, PRODUCTS, AND/OR SERVICES WILL MEET YOUR REQUIREMENTS; (II) THE SITE, THE SERVICES, AND ANY
                RELATED MATERIALS, PUBLISHED PRODUCTS AND/OR SERVICESWILL BE UNINTERRUPTED, TIMELY, SECURE, OR
                ERROR-FREE; (III) THE RESULTS THAT MAY BE DISPLAYED OR OBTAINED FROM THE USE OF THE SITE, THE SERVICES,
                ANY RELATED MATERIALS OR ANY PRODUCTS AND/OR SERVICES BEING MADE AVAILABLE TO YOU THROUGH THE SITE
                AND/OR SERVICES, WILL BE ACCURATE OR RELIABLE; (IV) THE QUALITY OF ANY CONTENT, INFORMATION, PRODUCTS,
                SERVICES, OR OTHER RELATED MATERIALS PROVIDED OR OFFERED THROUGH THE SITE AND/OR SERVICES WILL MEET YOUR
                EXPECTATIONS; AND (V) ANY ERRORS IN THE SITE, THE SERVICES, ANY RELATED MATERIALS, OR PUBLISHED CONTENT,
                OR ANY PRODUCTS AND/OR SERVICES MADE AVAILABLE TO YOU THROUGH THE SITE AND/OR SERVICES, WILL BE
                CORRECTED. ANY CONTENT AND INFORMATION, PRODUCTS, AND SERVICES OBTAINED THROUGH YOUR USE OF THE SITE
                AND/OR SERVICES IS DONE AT YOUR OWN DISCRETION AND RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY
                DAMAGE TO YOUR COMPUTER SYSTEM OR OTHER DEVICE, LOSS OF DATA, OR LOSS OF BUSINESS THAT RESULTS FROM YOUR
                USE OF THE SITE AND/OR SERVICES, OR THAT RESULT FROM YOUR USE OF ANY CONTENT, INFORMATION, PRODUCTS, AND
                SERVICES THAT ARE MADE AVAILABLE TO YOU THROUGH THE SITE AND/OR SERVICES. NO ADVICE OR INFORMATION,
                WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM REDBRICK OR THROUGH THE SITE SHALL CREATE ANY WARRANTY NOT
                EXPRESSLY STATED IN THIS AGREEMENT <br /> <br />
                <b>11. LIMITATION OF LIABILITY/INDEMNIFICATION - </b>Information on this Site and/or generated through
                the Services may contain technical inaccuracies or typographical errors although Redbrick will make
                commercially reasonable efforts to maintain and review all its content. Information and any content
                available on this Site and through the Services may be changed or updated at any time without prior
                notice to you. You will indemnify, hold harmless, and defend Redbrick, its employees, directors,
                officers, and authorized agents against any and all claims, proceedings, demand and costs (including
                litigation costs and attorneys’ fees) resulting from or in any way connected with your use of the Site,
                the Services, the information, and any content, products, and services that are made available on or
                through the Site and/or Services resulting from your breach of any of the terms and conditions of this
                Agreement. YOU ASSUME THE ENTIRE RISK OF USING THE SITE AND/OR SERVICES. IN NO EVENT (INCLUDING, WITHOUT
                LIMITATION, IN THE EVENT OF NEGLIGENCE) WILL REDBRICK, ITS EMPLOYEES, DIRECTORS, OFFICERS, OR AUTHORIZED
                AGENTS BE LIABLE FOR ANY ACTUAL, CONSEQUENTIAL, INCIDENTAL, DIRECT, INDIRECT, SPECIAL OR PUNITIVE
                DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS, LOSS OF USE, LOSS OF
                GOODWILL, LOSS OF BUSINESS, BUSINESS INTERRUPTION, LOSS OF INFORMATION OR DATA, OR PECUNIARY LOSS), IN
                CONNECTION WITH OR ARISING OUT OF OR RELATED TO THIS AGREEMENT, ANY SITE CONTENT, THE SERVICES, ANY
                RELATED MATERIALS, AND ANY PRODUCTS AND/OR SERVICES MADE AVAILABLE THROUGH THE SITE AND/OR SERVICES, OR
                THE USE OR INABILITY TO USE THE SITE AND/OR SERVICES, OR THE USE OR INABILITY TO USE ANY RELATED
                MATERIALS, PRODUCTS AND/OR SERVICES MADE AVAILABLE THROUGH THE SITE AND/OR SERVICES, OR THE FURNISHING,
                PERFORMANCE OR USE OF ANY OTHER MATTERS HEREUNDER WHETHER BASED UPON CONTRACT, TORT OR ANY OTHER THEORY
                INCLUDING NEGLIGENCE, EVEN IF EXPRESSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS
                DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN WARRANTIES. THEREFORE, SOME OF THE LIMITATIONS UNDER
                THIS AGREEMENT MAY NOT APPLY TO YOU.
                <br /> <br />
                <b>12. TERMINATION - </b>Redbrick reserves the right to terminate this Agreement and your access to the
                Site and/or Services, including without limitation, your access to any related materials, products
                and/or services, at any time and with or without cause and without notice.
                <br /> <br />
                <b>13. EQUITABLE RELIEF - </b>You agree that a material breach of this Agreement by you may cause
                irreparable harm and injury to Redbrick, and that money damages may not be a sufficient remedy for any
                such breach or threatened breach. Therefore, in addition to all other remedies available at law,
                Redbrick shall be entitled to seek specific performance and injunctive and other equitable relief as a
                remedy for such breach or threatened breach, and the you hereby waive any requirement for securing or
                posting of any bond or the showing of actual monetary damages in connection with such claim. Such
                remedies are not deemed to be exclusive remedies for any breach of this Agreement by you, but are in
                addition to all other remedies available at law or in equity to Redbrick.
                <br /> <br />
                <b>14. GOVERNING LAW – </b>This Agreement and all claims related to it, or the performance by both
                parties under it, shall be governed exclusively by the laws of the Province of British Columbia and the
                laws of Canada applicable therein except any principles regarding conflicts of law rules. You hereby
                irrevocably attorn and submit to the exclusive jurisdiction of the courts of Victoria, British Columbia,
                and any competent Courts of Appeal therefrom, and expressly and irrevocably waive any defense of
                personal and/or subject matter jurisdiction in those courts or any claim on the grounds of forum non
                conveniens. To the fullest extent permitted by applicable law: (i) no legal proceeding shall be joined
                with any other; (ii) there is no right or authority for any dispute under this Agreement to be litigated
                or resolved on a class-action basis or to utilize class action procedures; and (iii) there is no right
                or authority for any dispute under this Agreement to be brought in a purported representative capacity
                on behalf of the general public or any other person. YOU AGREE THAT YOU MAY BRING CLAIMS AGAINST
                REDBRICK ONLY IN YOUR INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS
                OR REPRESENTATIVE PROCEEDING.
                <br /> <br />
                <b>15. NOTICE AND AMENDMENT TO TERMS - </b>Redbrick reserves the right to amend the terms and conditions
                of this Agreement at any time and without prior notice to you. We shall notify you of any material
                changes to this Agreement by providing you with notification of such changes which will appear in a
                prominent location on the Site and/or through the Services. Your continued use of the Site and/or
                Services following any notice of changes will constitute as your acknowledgement and acceptance of any
                modified terms to the Agreement.
                <br /> <br />
                <b>16. ENTIRE AGREEMENT – </b>The terms and conditions of this Agreement (including any policies,
                guidelines or amendments that may be presented to you from time to time) constitute the entire agreement
                between you and Redbrick and govern your use of the Site, the Services, and any related materials,
                products and/or services made available to you through the Site and/or Services, superseding any prior
                agreements between you and Redbrick. You also may be subject to additional terms and conditions that may
                apply when you use any other current or future products and/or services made available through the Site
                and/or Services. If any provision of this Agreement is held by a court or other tribunal of competent
                jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated
                or limited to the minimum extent such that the remaining provisions of this Agreement will continue in
                full force and effect. No term or condition of the Agreement will be deemed waived, and no breach
                excused, unless such waiver or consent is in writing and signed by Redbrick. The failure of Redbrick to
                enforce at any time any of the provisions of this Agreement, or the failure to require at any time
                performance by you of any of the provisions of this Agreement, shall in no way be construed to be a
                present or future waiver of such provisions, nor in any way affect the ability of Redbrick to enforce
                each and every such provision thereafter.
                <br /> <br />
            </ParagraphWrapper>
        </Container>
    </ContainerWrapper>
)

export default TermsOfService
