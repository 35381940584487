import styled from '@emotion/styled'
import { HeadingsH2 } from 'styles/typography'
import goingCarbonNeutral from '../../assets/icons/going-carbon-neutral.svg'

// Wrapper for the image
const ImageWrapper = styled.div`
    height: 100%;
    img {
        height: 100%;
        width: 608px;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        img {
            width: 100%;
        }
    }
`
// Warpper for the paragraph
const ParagraphWrapper = styled.div`
    color: ${(props) => props.theme.primaryColor.black[75]};
    font-family: ${(props) => props.theme.typography.paragraph18.fontFamily};
    font-size: ${(props) => props.theme.typography.paragraph18.size};
    font-weight: ${(props) => props.theme.typography.paragraph18.weight};
    line-height: ${(props) => props.theme.typography.paragraph18.lineheight};
    letter-spacing: ${(props) => props.theme.typography.paragraph18.letterSpacing};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        width: unset;
    }
`

//wrappers for how-to text and button
const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    z-index: 5;
    gap: ${(props) => props.theme.spacing.m};
    width: 50%;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        width: auto;
        gap: ${(props) => props.theme.spacing.l};
        align-items: center;
        padding: ${(props) => props.theme.spacing.xl} 120px;
        max-width: unset;
        text-align: center;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        padding: ${(props) => props.theme.spacing.l} ${(props) => props.theme.spacing.s};
        gap: ${(props) => props.theme.spacing.m};
    }
`
const QuestionWrapper = styled(HeadingsH2)`
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        text-align: center;
        font-family: ${(props) => props.theme.typography.headingsH3.fontFamily};
        font-size: ${(props) => props.theme.typography.headingsH3.size};
        font-weight: ${(props) => props.theme.typography.headingsH3.weight};
        line-height: ${(props) => props.theme.typography.headingsH3.lineheight};
        letter-spacing: ${(props) => props.theme.typography.headingsH3.letterSpacing};
    }
`

const Container = styled.div`
    position: relative;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: ${(props) => props.theme.spacing.xl} 176px;
    max-width: 1088px;
    gap: 64px;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        flex-direction: column;
        align-items: center;
        padding: ${(props) => props.theme.spacing.xxl.default} 48px;
        gap: 48px;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        padding-left: ${(props) => props.theme.spacing.s};
        padding-right: ${(props) => props.theme.spacing.s};
    }
`
const ContentContainerWrapper = styled.div`
    margin: ${(props) => props.theme.spacing.xl} 0px;
    display: flex;
    flex-direction: column;
    z-index: 5;
    justify-content: space-between;
    align-items: left;
    gap: ${(props) => props.theme.spacing.xxl.default};
    text-align: center;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        margin: ${(props) => props.theme.spacing.xxl.default} ${(props) => props.theme.spacing.xxl.default};
        gap: ${(props) => props.theme.spacing.l};
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        margin: ${(props) => props.theme.spacing.xxl.default} ${(props) => props.theme.spacing.s};
    }
`
//wrapper for flex box
const MainWrapper = styled.div`
    position: relative;
`

//wrapper for flex box
const ContentWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        height: 100%;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        height: 100%;
    }
`

const HelpingEarth = () => (
    <MainWrapper>
        <ContentWrapper>
            <ContentContainerWrapper style={{ margin: '0px' }}>
                <Container>
                    <TextContainer>
                        <QuestionWrapper>Helping companies go carbon neutral</QuestionWrapper>
                        <ParagraphWrapper>
                            Your website relies on digital devices, data centers, and network infrastructure that
                            require a lot of energy. When that electricity isn’t green, it puts the demand on power
                            sources that are huge producers of CO2, like fossil fuels.
                            <p />
                            With potentially thousands of monthly visitors across software products, platforms, or
                            websites, your business is growing its emissions.
                        </ParagraphWrapper>
                    </TextContainer>
                    <ImageWrapper>{<img src={goingCarbonNeutral} alt='HelpingEarth' />}</ImageWrapper>
                </Container>
            </ContentContainerWrapper>
        </ContentWrapper>
    </MainWrapper>
)

export default HelpingEarth
