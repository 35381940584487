// Corporate Page

import { AllCaps16, Paragraph18 } from 'styles/typography'
import { BackgroundWrapper, ContentWrapper } from 'components/corporate/corporateBackground'
import {
    ActionWrapper,
    BottomWrapper,
    ConnectButton,
    ConnectTextBox,
    ConnectWrapper,
    HeadingWrapper,
    SDKWrapper,
    ScopeContainer,
    ScopeText,
    TargetText,
    TargetWrapper,
    TextContainer,
} from 'components/corporate/corporateContent'
import { goToTop, SimpleLink } from 'components/navbar'
import { SdkAnimation } from 'components/atoms/animations'

export default function Corporate() {
    return (
        <div>
            <BackgroundWrapper>
                <ContentWrapper>
                    <ActionWrapper>
                        <AllCaps16>Take action</AllCaps16>
                        <HeadingWrapper>Helping companies go carbon neutral</HeadingWrapper>
                    </ActionWrapper>
                    <TargetWrapper>
                        <TargetText>
                            Our digital carbon footprint is on track to be responsible for 14% of global emissions by
                            2040.
                        </TargetText>
                    </TargetWrapper>
                    <BottomWrapper>
                        <TextContainer>
                            <ConnectWrapper>
                                <ConnectTextBox>
                                    <Paragraph18>
                                        We’ve developed a custom tool for climate leaders who need clear data on how
                                        their digital presence impacts their total carbon emissions. <p />
                                        In addition to the carbon calculator, our team has created an SDK for
                                        JavaScript. Embedded in your site, this tool provides integrated carbon
                                        footprint measurement for clear, continual data on your digital Scope 3
                                        emissions.
                                    </Paragraph18>
                                </ConnectTextBox>
                                <ConnectButton>
                                    <SimpleLink to='/contact' style={{ color: 'white' }} onClick={goToTop}>
                                        Let's connect
                                    </SimpleLink>
                                </ConnectButton>
                            </ConnectWrapper>
                            <ScopeContainer>
                                <ScopeText>
                                    Scope 3 accounts for all the emissions an organization creates indirectly through
                                    its value chain, including the impact of assets and activities not necessarily owned
                                    or controlled by that organization.
                                </ScopeText>
                            </ScopeContainer>
                        </TextContainer>
                        <SDKWrapper>
                            <SdkAnimation />
                        </SDKWrapper>
                    </BottomWrapper>
                </ContentWrapper>
            </BackgroundWrapper>
        </div>
    )
}
