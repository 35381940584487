import * as React from 'react'
import Main from './pages/Main'
import { defaultTheme } from 'styles/theme'
import { ThemeProvider } from '@emotion/react'
import { CustomFooter } from 'components/footer'

export default function App() {
    return (
        <>
            <ThemeProvider theme={defaultTheme}>
                <div>
                    <Main />
                    <CustomFooter />
                </div>
            </ThemeProvider>
        </>
    )
}
